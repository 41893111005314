<header>
  <div class="logo">
    <a href="https://www.enphase.com/en-in" target="_blank"
      ><img src="/assets/images/enphase-logo.svg" loading="eager"  alt="enphase-logo" width="135" height="24"
    /></a>
  </div>
  <a href="https://india-estimator.enphaseenergy.com/" target="_blank">
  <p class="logo-text">System Estimator</p></a>

  <p class="support">
    <svg xmlns="http://www.w3.org/2000/svg" width="16.3" height="12.3" viewBox="0 0 16.3 12.3"><defs><style>.a-mail-icon{fill:#fff;stroke:#fff;stroke-width:0.3px;}</style></defs><g transform="translate(0.15 -63.85)"><g transform="translate(0 64)"><path class="a-mail-icon" d="M14.667,64H1.333A1.335,1.335,0,0,0,0,65.333v9.333A1.335,1.335,0,0,0,1.333,76H14.667A1.335,1.335,0,0,0,16,74.667V65.333A1.335,1.335,0,0,0,14.667,64Zm-13.333.667H14.667a.634.634,0,0,1,.138.028c-1.154,1.056-4.982,4.558-6.321,5.765a.669.669,0,0,1-.968,0c-1.339-1.207-5.167-4.708-6.321-5.765A.637.637,0,0,1,1.333,64.667Zm-.667,10V65.333A.644.644,0,0,1,.7,65.149c.884.809,3.554,3.252,5.286,4.827C4.264,71.458,1.592,73.991.7,74.84A.642.642,0,0,1,.667,74.667Zm14,.667H1.333a.638.638,0,0,1-.15-.03c.92-.877,3.609-3.425,5.3-4.875l.582.527a1.332,1.332,0,0,0,1.86,0l.583-.527c1.7,1.451,4.384,4,5.3,4.875A.637.637,0,0,1,14.667,75.333Zm.667-.667a.644.644,0,0,1-.035.174c-.891-.85-3.562-3.382-5.288-4.865,1.732-1.575,4.4-4.018,5.286-4.827a.644.644,0,0,1,.037.184Z" transform="translate(0 -64)"/></g></g></svg>    <a href="mailto:india@enphase.com">Get Enphase Support</a>
    <span class="h-tooltip">  india@enphase.com </span>
  </p>
</header>
