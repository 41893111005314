import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import baseapi from './base/baseapi.json';
@Injectable({
  providedIn: 'root',
})
export class pdfService {
  shwoDownloadButton = new BehaviorSubject(false);
  sendUserData = new BehaviorSubject({});

  constructor(private http: HttpClient) {}
  getHeader(): any {
    return new HttpHeaders().set('Accept', 'application/pdf');
  }

  getPdf(
    name: any,
    phone: any,
    email: any,
    monthlyElectricityBill: any,
    units: any,
    panels: any,
    roofArea: any,
    payBackPeriod: any,
    withoutSolar: any,
    savings: any,
    pincode: any,
    totalCost: any,
    suryagharsubsidy: any,
    statesubsidy: any,
    netcost:any,
    pvSize: any
  ): any {
    let data = {};
    if (!email) {
      data= {
        name,
        phone,
        email,
        monthlyElectricityBill,
        units,
        panels,
        roofArea,
        payBackPeriod,
        withoutSolar,
        savings,
        pincode,
        totalCost,
        suryagharsubsidy,
    statesubsidy,
    netcost,
        pvSize,
      };
    }else {
      data = {
        name, 
        phone,
        email,
        monthlyElectricityBill,
        // bill,
        units,
        panels,
        roofArea,
        payBackPeriod,
        withoutSolar,
        savings,
        pincode,
        totalCost,
        suryagharsubsidy,
    statesubsidy,
    netcost,
        pvSize,
      };
    }
    return this.http
      .post<any>(baseapi.getPdf, data,{
        
        headers: this.getHeader(),
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((getPdf) => {
          return getPdf;
        })
      );
  }
}
