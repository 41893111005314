import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { LoaderService } from '../shared/loader.service';
import { MailService } from '../shared/mail.service';
import { CaptchaService } from '../shared/captcha.service';
import { NotificationsService } from '../shared/notifications.service';
import { ThankyoudialogComponent } from '../thankyoudialog/thankyoudialog.component';
import { environment } from 'src/environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ReCaptchaService } from 'angular-recaptcha3';
// tslint:disable-next-line:ban-types
declare var gtag: Function;
@Component({
  selector: 'app-large-pvsize-modal',
  templateUrl: './large-pvsize-modal.component.html',
  styleUrls: ['./large-pvsize-modal.component.scss'],
})
export class LargePVSizeModalComponent implements OnInit {
  contactForm: FormGroup;
  private urlhttpClient: HttpClient;
  url: any;
  bills: any;
  estPrice: any;
  slideValue: any;
  docService: any;

  ebill: any;
  pincode: any;
  recommended: any;

  secret = '6LdGWAkdAAAAAMXGRxsuM1M53kMt7ayRAb9D2q8k';

  handleSuccess(e: any) {

  }

  constructor(
    handler: HttpBackend,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<LargePVSizeModalComponent>,
    public dialog: MatDialog,
    private mailService: MailService,
    private captchaService: CaptchaService,
    private loadService: LoaderService,
    private notificationService: NotificationsService,
    private gtmService: GoogleTagManagerService,
    private recaptchaService: ReCaptchaService,
    private http: HttpClient
  ) {
    this.contactForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      phone: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10),
        Validators.pattern('^[0-9]+$'),
      ]),
      email: new FormControl('', [
        Validators.pattern('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$'),
      ]),
      pincode: new FormControl(localStorage.getItem('pincode'), [
        Validators.required,
        Validators.maxLength(6),
        Validators.minLength(6),
        Validators.pattern('^[0-9]+$'),
      ]),
      accept: new FormControl('', [Validators.required]),
      captcha: new FormControl(''),
    });
    this.urlhttpClient = new HttpClient(handler);
  }

  ngOnInit(): void {
    this.ebill = localStorage.getItem('electricityBill');
    this.pincode = localStorage.getItem('pincode');
    this.recommended = localStorage.getItem('RecommendedData');
  }

  customEvent(buttonText: string): void {
    // push GTM data layer with a custom event
    const gtmTag = {
      data: 'button-click',
      event: buttonText,
    };
    this.gtmService.pushTag(gtmTag);
  }

  onSubmit(): void {
    this.loadService.showLoader();
    this.recaptchaService.execute().then((token: any) => {
      this.captchaService.checkToken(token).subscribe(
        (res: any) => {
          if(res.response.success) {
            this.loadService.hideLoader();
            this.submitData();
          } else {
            this.loadService.hideLoader();
            this.notificationService.error('Error in Validating The Form, Please try again');
          }
        },
        (err: any) => {
          this.loadService.hideLoader();
          this.notificationService.error('Error in Validating The Form');
        }
      );
    });
  }

  submitData() {
    if (this.contactForm.invalid) {
      return;
    } else {
      if (!localStorage.getItem('formSubmitted')) {
        this.customEvent('India_estimator_LargePV_submit_success');
      }
      localStorage.setItem('formSubmitted', 'true');
      this.close();
      let email = this.contactForm.get('email')?.value;
      let name = this.contactForm.get('name')?.value;
      let firstName = name?.split(' ')[0]
      if (email) {
        this.loadService.showLoader();
        this.mailService
          .sendMail({
            email: email,
            leadName: firstName
          })
          .subscribe(
            (res: any) => {
              if (res.status === 200) {
                this.sendCURLRequest();
                this.loadService.hideLoader();
                this.dialog.open(ThankyoudialogComponent, {panelClass: 'hide-download-btn'});
              }
              if (res.status === 406) {
                this.notificationService.warning('Something Went Wrong');
              }
              if (res.status === 500) {
                this.notificationService.error('Internal Error');
              }
            },
            (err: any) => {
              this.loadService.hideLoader();
              this.notificationService.error('Error in sending mail');
            }
          );
      } else {
        this.sendCURLRequest();
        this.dialog.open(ThankyoudialogComponent, {panelClass: 'hide-download-btn'});
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  sendCURLRequest(): void {
    // Add all the Data in URL
    const fullName = this.contactForm.value.name;
    const firstName = fullName.split(' ')[0];
    const lastName = fullName?.split(' ')[1];
    const phone = this.contactForm.value.phone;
    const email = this.contactForm.value.email;
    const zipcode = localStorage.getItem('pincode');
    const unit = localStorage.getItem('unit');
    const gclid = JSON.parse(localStorage.getItem('gclid') || '{}')?.value;
    const utmSource = sessionStorage.getItem('utm_source');
    const utmMedium = sessionStorage.getItem('utm_medium');
    const utmCamp = sessionStorage.getItem('utm_campaign');
    const utmContent = sessionStorage.getItem('utm_content');
    const utmTerm = sessionStorage.getItem('utm_term');
    const time = sessionStorage.getItem('duration');

    const timeSpent = new Date(time as any).getTime();
    const strObject = this.recommended;
    const recObj = JSON.parse(strObject);
    const estimated = Math.floor(recObj.newEstimatedPriceAfterSubsidy.min);
    const savings = Math.floor(recObj.savingsFor25Years);
    const curentTime = new Date().getTime();

    const spent = Math.floor((curentTime - timeSpent) / 1000);

    let detectOS = 'Unknown OS';
    let browserName = 'Unknown';
    if (navigator.platform.indexOf('Win') !== -1) {
      detectOS = 'Windows';
    }

    if (navigator.platform.indexOf('Mac') !== -1) {
      detectOS = 'MacOS';
    }

    if (navigator.platform.indexOf('Linux') !== -1) {
      detectOS = 'Linux';
    }
    const osv = navigator.appVersion.split(' ');
    const v = [];
    v.push(osv[1]);
    v.push(osv[2]);
    v.push(osv[3]);
    v.push(osv[4]);
    v.push(osv[5]);
    v.join(' ');

    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      browserName = 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      browserName = 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      browserName = 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      browserName = 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1) {
      // IF IE > 10
      browserName = 'IE';
    } else {
      browserName = 'Unknown';
    }

    const bVersion = (() => {
      var ua = navigator.userAgent,
        tem,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(' ');
    })();

    this.url = `
     ${environment.leadconduitURL}?gcclid=${gclid}&
      first_name=${firstName}&last_name=${lastName}&email=${email}&zipcode=${zipcode}&contact_number=${phone}
      &country=IN&consent=TRUE&product=solar&home_size=${
        recObj.idealRoofSize
      }&pv_capacity=${
      recObj.pvSize
    }&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCamp}&utm_content=${utmContent}
    &utm_term=${utmTerm}
      &time_spent_on_page=${
        spent + ' s'
      }&os_name=${detectOS}&os_version=${v}&browser_name=${browserName}&browser_version=${bVersion}
      &navigator_user_agent=${navigator.userAgent}&navigator_app_version=${
      navigator.appVersion
    }&navigator_platform=${navigator.platform}
      &navigator_vendor=${navigator.vendor}&ec_bill_amt=${
      this.ebill
    }&existing_panels_count=${recObj.panelCount}&total_savings=${savings}
      &estimated_investment=${estimated}&payback_period=${
      Math.round(recObj.payBackPeriod.min)
    }&no_of_units=${
      recObj.units
    }&page_url=&source_details=&oid=00D63000000U0i6&new_electricity_bill=${
      recObj.newMonthlyElectricityBill
    }
      &original_source=${'IN System Estimator'}&lead_source_page_url=${
      window.location.host
    }`;

    this.urlhttpClient
      .get(this.url, {
        headers: new HttpHeaders().set(
          'Content-Type',
          'application/x-www-form-urlencoded'
        ),
        responseType: 'text',
      })
      .subscribe(
        (res: any) => {
          if (res.status === 406) {
            this.notificationService.warning('Something Went Wrong');
          }
          if (res.status === 500) {
            this.notificationService.error('Internal Error');
          }
        },
        (err) => {
          // Error callback
          this.notificationService.error('Error, Please try again');
        }
      );
  }
}
