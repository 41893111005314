import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from '../shared/loader.service';
import { pdfService } from '../shared/pdf.service';
import { IndiancurrencyPipe } from '../shared/indiancurrency.pipe';

import { NotificationsService } from '../shared/notifications.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-thankyoudialog',
  templateUrl: './thankyoudialog.component.html',
  styleUrls: ['./thankyoudialog.component.scss'],
})
export class ThankyoudialogComponent implements OnInit {

  showDownloadButton = false;

  constructor(
    public dialogRef: MatDialogRef<ThankyoudialogComponent>,
    private router: Router,
    private loadService: LoaderService,
    private docService: pdfService,
    private cpipe: IndiancurrencyPipe,
    private notificationService: NotificationsService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    // (window as any).gtag('event', 'conversion', {'send_to':'AW-586554629/6ChYCLawn7EDEIW62JcC'});
  }

  close(): void {
    this.dialogRef.close();
  }
  downloadPDF(): void {
    // (window as any).dataLayer.push({ event: 'Download' });
    if (!localStorage.getItem('pdfDownloaded')) {
      this.customEvent('India_estimator_download');
    }
    localStorage.setItem('pdfDownloaded', 'true');
    window.scrollTo(0, 0);

    setTimeout(() => {
      this.loadService.showLoader();

      let full_name = localStorage.getItem('name');

      const phone = localStorage.getItem('phone');
      const email = localStorage.getItem('email');
      const zipcode = localStorage.getItem('pincode');
      const unit = localStorage.getItem('unit') || 0;
      // const bill = localStorage.getItem('electricityBill');
      const withoutSolar = localStorage.getItem('electricityBill');
      const strObject = localStorage.getItem('RecommendedData') || '';
      const recObj = JSON.parse(strObject);
      const savings = this.cpipe.transform(recObj.savingsFor25Years);
      // const savings = {
      //   min:this.cpipe.transform(recObj.savingsFor25Years.min), 
      //   max:this.cpipe.transform(recObj.savingsFor25Years.max)
      // };
      const netcost = { 
        min:this.cpipe.transform(recObj.newEstimatedPriceAfterSubsidy.min),
        max:this.cpipe.transform(recObj.newEstimatedPriceAfterSubsidy.max)
      }
      const totalCost = { 
        min:this.cpipe.transform(recObj.estimatedPrice.min),
        max:this.cpipe.transform(recObj.estimatedPrice.max)
      }
      const suryagharsubsidy = this.cpipe.transform(recObj.CentralSubsidy)
      let traESPrice = this.cpipe.transform(recObj.estimatedPrice.toFixed(0));
      const unitWithComma = Number(unit).toLocaleString('en-IN');
      this.docService
      .getPdf(
        full_name,
        phone,
        email,
        recObj.newMonthlyElectricityBill,
        // 47379,
        unitWithComma,
        recObj.panelCount,
        recObj.idealRoofSize,
        recObj.payBackPeriod,
        // recObj.payBackPeriod.min + "-" + recObj.payBackPeriod.max,
        withoutSolar,
        savings,

        // recObj.savingsFor25Years.min + "-" + recObj.savingsFor25Years.max,
        zipcode,
        totalCost,
        suryagharsubsidy,
          recObj.stateSubsidy,
       
      
        // statesubsidy,
        netcost,
        // traESPrice,
        // recObj.estimatedPrice.min + "-" + recObj.estimatedPrice.max,
        recObj.pvSize
      )
        .subscribe(
          (res: any) => {
            this.loadService.hideLoader();

            if (res.size > 0) {
              let file = new Blob([res], { type: 'application/pdf' });
              saveAs(file, `Report.pdf`);
            } else {
              this.notificationService.error('Error in download ');
            }
          },
          (err: any) => {
            this.loadService.hideLoader();
            this.notificationService.error('Error in downloading the pdf');
          }
        );
    }, 500);
  }
  customEvent(buttonText: string): void {
    // push GTM data layer with a custom event
    const gtmTag = {
      data: 'button-click',
      event: buttonText,
    };
    this.gtmService.pushTag(gtmTag);
  }
}
