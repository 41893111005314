import { Injectable } from '@angular/core';

import baseapi from './base/baseapi.json';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class CaptchaService {

    constructor(private http: HttpClient) { }

    getHeader(): any {
        return new HttpHeaders()
            .set('enctype', 'multipart/form-data')
            .set('Accept', 'application/json');
    }

    getCaptcha(): any {
        return this.http.get(baseapi.captcha, {
            params: {},
            headers: this.getHeader(),
            observe: 'response',
        });
    }

    checkToken(token: any): any {
        return this.http.post(baseapi.captchaTokenVerify, {
            "g-recaptcha-response": token
        });
    }

}
