import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { ElecricityBillService } from '../shared/elecricity-bill.service';
import { ConnectWithExpertsComponent } from '../connect-with-experts/connect-with-experts.component';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from '../shared/loader.service';
import { pdfService } from '../shared/pdf.service';
import { IndiancurrencyPipe } from '../shared/indiancurrency.pipe';

import { saveAs } from 'file-saver';
import { NotificationsService } from '../shared/notifications.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: [
    '../front-page/front-page.component.scss',
    './recommended.component.scss',
  ],
  animations: [
    trigger('flyIn', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [
        style({ transform: 'translateY(-14px)', opacity: 0 }),
        animate('0.5s ease-out'),
      ]),
    ]),
  ],
})
export class RecommendedComponent implements OnInit {
  loading = false;
  slideValue = 2.8;
  bills: any;
  pin: any = "";
  units: any;
  ebill: any;
  note: any;
  pdf: any;

  estPrice: any;

  showDownloadButton = false;
  minUnit = 0;
  maxUnit = 0;

  unitSlideValue = 0;
  formated25yearSavings = '';

  constructor(
    private router: Router,
    private eService: ElecricityBillService,
    public dialog: MatDialog,
    private loadService: LoaderService,
    private docService: pdfService,
    private cpipe: IndiancurrencyPipe,
    private notificationService: NotificationsService,
    private activatedRoute: ActivatedRoute,
    private gtmService: GoogleTagManagerService
  ) {
    // this.router.events.forEach((item) => {
    //   if (item instanceof NavigationEnd) {
    //     const gtmTag = {
    //       event: 'page',
    //       pageName: item.url,
    //     };
    //     this.gtmService.pushTag(gtmTag);
    //   }
    // });
    this.pin = localStorage.getItem('pincode');
    this.units = localStorage.getItem('unit');
    this.ebill = localStorage.getItem('electricityBill');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnInit(): void {
    // if (this.previousDataIsNotAvailable()) {
    //   this.loadService.hideLoader();
    //   this.router.navigate(['/']);
    // }
   

    if (!this.pin) {
      this.router.navigate(['/']);
    } else {
      this.loadService.showLoader();
      const editedUnits = this.activatedRoute.snapshot.params.editedUnits;
      if (editedUnits) {
        this.getEbillFromEditedUnitsAPI(editedUnits);
      } else {
        this.getEbillFromAPI();
      }
      this.setMinMaxUnits();

      this.docService.shwoDownloadButton.subscribe((value: boolean) => {
        this.showDownloadButton = value;
      });
    }
  }

  previousDataIsNotAvailable(): boolean {
    let pin = localStorage.getItem('pincode');
    let bill = localStorage.getItem('electricityBill');
    return !pin && !bill;
  }

  getEbillFromEditedUnitsAPI(editedUnits: any): void {
    this.eService.editUnits(this.pin, editedUnits, this.ebill).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.bills = res.body.result;
          this.note = res.body.result.note;
          this.format25YearSavings(this.bills.savingsFor25Years);
          this.estPrice = this.bills.estimatedPrice;
          this.slideValue = res.body.result.pvSize;
          this.loadService.hideLoader();

          // Data to be used later in CURL
          localStorage.setItem('RecommendedData', JSON.stringify(this.bills));
        }
        if (res.status === 406) {
          this.notificationService.warning('Something Went Wrong');
        }
        if (res.status === 500) {
          this.notificationService.error('Internal Error');
        }
      },
      (err: any) => {
        this.notificationService.error('Error in fetching data');
      }
    );
  }

  getEbillFromAPI(): void {
    this.loadService.showLoader();

    this.eService.getEBilReports(this.ebill, this.pin).subscribe(
      (res: any) => {

        if (res.status === 200) {
          this.bills = res.body.result;
          this.note = res.body.result.note;
          this.format25YearSavings(this.bills.savingsFor25Years);
          this.estPrice = this.bills.estimatedPrice
          this.slideValue = res.body.result.pvSize;
          this.loadService.hideLoader();

          // Data to be used later in CURL
          localStorage.setItem('RecommendedData', JSON.stringify(this.bills))
  
        }
        if (res.status === 406) {
          this.notificationService.warning('Something Went Wrong');
        }
        if (res.status === 500) {
          this.notificationService.error('Internal Error');
        }
        this.loadService.hideLoader();
      },
      (err: any) => {
        this.loadService.hideLoader();
        this.notificationService.error('Error in fetching data');
      }
    );
  }

  openDialog(gtag: String): void {
    if (!localStorage.getItem('formSubmitted')) {
      this.customEvent(gtag);
    }
    this.dialog.open(ConnectWithExpertsComponent);
  }

  customEvent(buttonText: String): void {
    // push GTM data layer with a custom event
    const gtmTag = {
      data: 'button-click',
      event: buttonText,
    };
    this.gtmService.pushTag(gtmTag);
  }

  setMinMaxUnits(): void {
    const localUnits = Number(localStorage.getItem('unit'));
    this.minUnit = Math.floor(localUnits - (localUnits * 15) / 100);
    this.maxUnit = Math.floor(localUnits + (localUnits * 15) / 100);
  }

  callAPIHear(value: any): any {
    this.loadService.showLoader();

    this.eService.editPVSize(this.pin, value, this.ebill).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.bills = res.body.result;
       
          this.format25YearSavings(this.bills.savingsFor25Years);

          this.estPrice = this.bills.estimatedPrice;
          this.loadService.hideLoader();
          localStorage.setItem('RecommendedData', JSON.stringify(this.bills));
        }
        if (res.status === 406) {
          this.notificationService.warning('Something Went Wrong');
        }
        if (res.status === 500) {
          this.notificationService.error('Internal Error');
        }
      },
      (err: any) => {
        this.loadService.hideLoader();
        this.notificationService.error('Error in fetching data');
      }
    );
  }

  editInfo() {
    // localStorage.setItem('editPage', 'true');
    this.router.navigate(['/']);
  }

  format25YearSavings(savings: number) {
    if (savings >= 10000000) {
      this.formated25yearSavings = (savings / 10000000).toFixed() + ' Crores';
    } else if (savings > 99999 && savings < 10000000) {
      this.formated25yearSavings = (savings / 100000).toFixed() + ' Lakhs';
    } else if (savings < 100000) {
      this.formated25yearSavings = (savings / 1000).toFixed() + 'Thousands';
    }
  }

  downloadPDF(): void {
    // (window as any).dataLayer.push({ event: 'Download' });
    if (!localStorage.getItem('pdfDownloaded')) {
      this.customEvent('India_estimator_download');
    }
    localStorage.setItem('pdfDownloaded', 'true');
    window.scrollTo(0, 0);

    setTimeout(() => {
      this.loadService.showLoader();

      let full_name = localStorage.getItem('name');

      const phone = localStorage.getItem('phone');
      const email = localStorage.getItem('email');
      const zipcode = localStorage.getItem('pincode');
      const unit = localStorage.getItem('unit') || 0;
      const bill = localStorage.getItem('electricityBill');
      const strObject = localStorage.getItem('RecommendedData') || '';
      const recObj = JSON.parse(strObject);
      // const statesubsidy = this.cpipe.transform(recObj.stateSubsidy)
      const savings = this.cpipe.transform(recObj.savingsFor25Years);
  //     const savings = {
  //       min:this.cpipe.transform(recObj.savingsFor25Years.min), 
  //       max:this.cpipe.transform(recObj.savingsFor25Years.max)
  // };
  const netcost = { 
    min:this.cpipe.transform(recObj.newEstimatedPriceAfterSubsidy.min),
    max:this.cpipe.transform(recObj.newEstimatedPriceAfterSubsidy.max)
  }
  const totalCost = { 
    min:this.cpipe.transform(recObj.estimatedPrice.min),
    max:this.cpipe.transform(recObj.estimatedPrice.max)
  }
  const suryagharsubsidy = this.cpipe.transform(recObj.CentralSubsidy)
      // let traESPrice = this.cpipe.transform(recObj.estimatedPrice.toFixed(0));
      const unitWithComma = Number(unit).toLocaleString('en-IN');
      this.docService
      .getPdf(
        full_name,
        phone,
        email,
        recObj.newMonthlyElectricityBill,
        // 47379,
        unitWithComma,
        recObj.panelCount,
        recObj.idealRoofSize,
        recObj.payBackPeriod,
        // recObj.payBackPeriod.min + "-" + recObj.payBackPeriod.max,
        // this.cpipe.transform(Number(bill)),
        bill,
        savings,

        // recObj.savingsFor25Years.min + "-" + recObj.savingsFor25Years.max,
        zipcode,
        totalCost,
       
        suryagharsubsidy,
        recObj.stateSubsidy,
     
      
        netcost,
        // traESPrice,
        // recObj.estimatedPrice.min + "-" + recObj.estimatedPrice.max,
        recObj.pvSize
      )
        .subscribe(
          (res: any) => {
            this.loadService.hideLoader();

            if (res.size > 0) {
              let file = new Blob([res], { type: 'application/pdf' });
              saveAs(file, `Report.pdf`);
            } else {
              this.notificationService.error('Error in download ');
            }
          },
          (err: any) => {
            this.loadService.hideLoader();
            this.notificationService.error('Error in downloading the pdf');
          }
        );
    }, 500);
  }
}
