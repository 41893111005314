import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LoaderService } from '../shared/loader.service';
import { MailService } from '../shared/mail.service';
import { CaptchaService } from '../shared/captcha.service';
import { ThankyoudialogComponent } from '../thankyoudialog/thankyoudialog.component';
import { pdfService } from '../shared/pdf.service';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { NotificationsService } from '../shared/notifications.service';
import { environment } from '../../environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ReCaptchaService } from 'angular-recaptcha3';
import { from } from 'rxjs';
// tslint:disable-next-line:ban-types
declare var gtm: Function;
@Component({
  selector: 'app-connect-with-experts',
  templateUrl: './connect-with-experts.component.html',
  styleUrls: ['./connect-with-experts.component.scss'],
})
export class ConnectWithExpertsComponent implements OnInit {
  contactForm: FormGroup;
  url: string = ``;
  private urlhttpClient: HttpClient;
  captchaData = { image: '', text: '' };
  wrongCaptcha = false;

  secret = '6LdGWAkdAAAAAMXGRxsuM1M53kMt7ayRAb9D2q8k';

  constructor(
    handler: HttpBackend,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private mailService: MailService,
    public dialogRef: MatDialogRef<ConnectWithExpertsComponent>,
    private captchaService: CaptchaService,
    private loadService: LoaderService,
    private docService: pdfService,
    private notificationService: NotificationsService,
    private gtmService: GoogleTagManagerService,
    private recaptchaService: ReCaptchaService,
    private router : Router,
  ) {
    this.contactForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      phone: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10),
        Validators.pattern('^[0-9]+$'),
      ]),
      email: new FormControl('', [
        Validators.pattern('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$'),
      ]),
      accept: new FormControl('', [Validators.required]),
      captcha: new FormControl(''),
    });

    this.urlhttpClient = new HttpClient(handler);
  }

  ngOnInit(): void {}

  customEvent(buttonText: string): void {
    // push GTM data layer with a custom event
    const gtmTag = {
      data: 'button-click',
      event: buttonText,
    };
    this.gtmService.pushTag(gtmTag);
  }
  
  onSubmit(): void {
    this.loadService.showLoader();
    this.recaptchaService.execute().then((token: any) => {
      this.captchaService.checkToken(token).subscribe(
        (res: any) => {
          
          if (res.response.success) {
            this.loadService.hideLoader();
            localStorage.setItem('form-submit',"true");
            this.submitData();
          } else {
            this.loadService.hideLoader();
            this.notificationService.error(
              'Error in Validating The Form, Please try again'
            );
          }
        },
        (err: any) => {
          this.loadService.hideLoader();
          this.notificationService.error('Error in Validating The Form');
        }
      );
    });
  }
  submitData() {
    if (this.contactForm.invalid) {
      return;
    } else {
      if (!localStorage.getItem('formSubmitted')) {
        this.customEvent('India_estimator_submit_success');
      }
      localStorage.setItem('formSubmitted', 'true');
      this.close();
      let email = this.contactForm.get('email')?.value;
      let fullName = this.contactForm.get('name')?.value;
      let firstName = fullName?.split(' ')[0]
      if (email) {
        this.loadService.showLoader();
        this.mailService
          .sendMail({
            email: email,
            leadName: firstName
          })
          .subscribe(
            (res: any) => {
              if (res.status === 200) {
                this.loadService.hideLoader();
                //this.dialog.open(ThankyoudialogComponent, {panelClass: 'hide-thankyou-text'});
                 this.router.navigate(['thankyou']); 
                this.docService.shwoDownloadButton.next(true);
                this.docService.sendUserData.next({
                  name: this.contactForm.get('name')?.value,
                  phone: this.contactForm.get('phone')?.value,
                  email: email,
                });
                localStorage.setItem(
                  'name',
                  this.contactForm.get('name')?.value
                );
                localStorage.setItem(
                  'phone',
                  this.contactForm.get('phone')?.value
                );
                localStorage.setItem('email', email);

                // CURL Code
                this.sendCURLRequest();
              }
              if (res.status === 406) {
                this.notificationService.warning('Something Went Wrong');
              }
              if (res.status === 500) {
                this.notificationService.error('Internal Error');
              }
            },
            (err: any) => {
              this.loadService.hideLoader();
              this.notificationService.error('Error in sending mail');
            }
          );
      } else {
        // this.dialog.open(ThankyoudialogComponent, {panelClass: 'hide-thankyou-text'});
        this.router.navigate(['/thankyou']);
        this.docService.shwoDownloadButton.next(true);
        this.docService.sendUserData.next({
          name: this.contactForm.get('name')?.value,
          phone: this.contactForm.get('phone')?.value,
          email: ''
        });
        localStorage.setItem(
          'name',
          this.contactForm.get('name')?.value
        );
        localStorage.setItem(
          'phone',
          this.contactForm.get('phone')?.value
        );
        localStorage.setItem('email', '');
        this.sendCURLRequest();
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  sendCURLRequest(): void {
    // Add all the Data in URL

    // total cost - estimated_investment --done
    // netcost - estimated_net_investment --done
    // central-subsidy - suryaghar_subsidy --done
    // state-subsidy - state_subsidy
 
    let full_name = localStorage.getItem('name');
    const first_name = full_name?.split(' ')[0];
    const last_name = full_name?.split(' ')[1];
    const phone = localStorage.getItem('phone');
    const email = localStorage.getItem('email');
    const zipcode = localStorage.getItem('pincode');
    const bill = localStorage.getItem('electricityBill');
    const unit = localStorage.getItem('unit');
    const gclid = JSON.parse(localStorage.getItem('gclid') || '{}')?.value;
    const strObject = localStorage.getItem('RecommendedData') || '';
    const utmSource = sessionStorage.getItem('utm_source');
    const utmMedium = sessionStorage.getItem('utm_medium');
    const utmCamp = sessionStorage.getItem('utm_campaign');
    const utmContent = sessionStorage.getItem('utm_content');
    const utmTerm = sessionStorage.getItem('utm_term');
    const time = sessionStorage.getItem('duration');
    const timeSpent = new Date(time as any).getTime();

    const curentTime = new Date().getTime();

    const spent = Math.floor((curentTime - timeSpent) / 1000);

    const recObj = JSON.parse(strObject);
    // let estimated_investment = (recObj.estimatedPrice);
    let estimated_net_investment = (recObj.newEstimatedPriceAfterSubsidy);
    // let suryaghar_subsidy = (recObj.CentralSubsidy);
    // let state_subsidy = (recObj.stateSubsidy);
    let payback_period = (recObj.payBackPeriod);
    
    let savings = (recObj.savingsFor25Years);
    let detectOS = 'Unknown OS';
    let browserName = 'Unknown';
    if (navigator.platform.indexOf('Win') !== -1) {
      detectOS = 'Windows';
    }

    if (navigator.platform.indexOf('Mac') !== -1) {
      detectOS = 'MacOS';
    }

    if (navigator.platform.indexOf('Linux') !== -1) {
      detectOS = 'Linux';
    }

    const osv = navigator.appVersion.split(' ');
    const v = [];
    v.push(osv[1]);
    v.push(osv[2]);
    v.push(osv[3]);
    v.push(osv[4]);
    v.push(osv[5]);
    v.join(' ');

    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      browserName = 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      browserName = 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      browserName = 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      browserName = 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1) {
      // IF IE > 10
      browserName = 'IE';
    } else {
      browserName = 'Unknown';
    }

    const bVersion = (() => {
      var ua = navigator.userAgent,
        tem,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) {
          return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }
      return M.join(' ');
    })();

    this.url = `
      ${environment.leadconduitURL}?gcclid=${gclid}&
      first_name=${first_name}&last_name=${last_name}&email=${email}&zipcode=${zipcode}&contact_number=${phone}
      &country=IN&consent=TRUE&product=solar&home_size=${
        recObj.idealRoofSize
      }&pv_capacity=${
      recObj.pvSize
    }&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCamp}&utm_content=${utmContent}
    &utm_term=${utmTerm}
      &time_spent_on_page=${
        spent + ' s'
      }&os_name=${detectOS}&os_version=${v}&browser_name=${browserName}&browser_version=${bVersion}
      &navigator_user_agent=${navigator.userAgent}&navigator_app_version=${
      navigator.appVersion
    }&navigator_platform=${navigator.platform}
      &navigator_vendor=${
        navigator.vendor
      }&ec_bill_amt=${bill}&existing_panels_count=${
      recObj.panelCount
    }&total_savings=${Math.floor(savings)}
      &estimated_investment=${Math.floor(estimated_net_investment.min)}
      &payback_period=${Math.round(payback_period.min)}
      &no_of_units=${unit}&page_url=&source_details=&oid=00D63000000U0i6&new_electricity_bill=${
      recObj.newMonthlyElectricityBill
    }&original_source=${'IN System Estimator'}&lead_source_page_url=${
      window.location.host
    }`;

    this.urlhttpClient
      .get(this.url, {
        headers: new HttpHeaders().set(
          'Content-Type',
          'application/x-www-form-urlencoded'
        ),
        responseType: 'text',
      })
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
          }
          if (res.status === 406) {
            this.notificationService.warning('Something Went Wrong');
          }
          if (res.status === 500) {
            this.notificationService.error('Internal Error');
          }
        },
        (err) => {
          // Error callback
          this.notificationService.error('Error ');
        }
      );
  }
}
