<button class="close-btn" mat-dialog-close>
  <img src="/assets/images/close_icon.svg" alt="close" width="14" height="14" />
</button>
<div class="modal-container">
  <p class="m-title">
    Connect with our experts by sharing your contact details.
  </p>
  <form class="c-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <div class="validation-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>Full Name<span>*</span></mat-label>
        <input type="text" matInput placeholder="Full Name" name="name" formControlName="name" />
      </mat-form-field>
      <div class="invalid-data" *ngIf="
          contactForm.controls.name.invalid && contactForm.controls.name.touched
        ">
        <div *ngIf="contactForm.controls.name.errors?.required">
          Name is required
        </div>
      </div>
    </div>
    <div class="validation-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>Phone Number<span>*</span></mat-label>
        <input type="tel" matInput placeholder="Phone no" formControlName="phone" />
        <span matPrefix>+91</span>
      </mat-form-field>
      <div class="invalid-data" *ngIf="
          contactForm.controls.phone.invalid &&
          contactForm.controls.phone.touched
        ">
        <div *ngIf="contactForm.controls.phone.errors?.required">
          Phone Number is required
        </div>
        <div *ngIf="contactForm.controls.phone.errors?.pattern">
          Please Enter a valid Phone Number
        </div>
        <div *ngIf="
            contactForm.controls.phone.errors?.minlength ||
            contactForm.controls.phone.errors?.maxlength
          ">
          Phone Number must be 10 digits long
        </div>
      </div>
    </div>
    <div class="validation-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" formControlName="email" />
      </mat-form-field>
      <div class="invalid-data" *ngIf="
          contactForm.controls.email.invalid &&
          contactForm.controls.email.touched
        ">
        <div *ngIf="contactForm.controls.email.errors?.pattern">
          Please Enter a valid Email
        </div>
      </div>
    </div>
    <div class="validation-wrapper">
      <mat-radio-group aria-label="Accept Privacy Policy and Terms and Condition" formControlName="accept">
        <mat-radio-button value="1"></mat-radio-button>
        <!-- <p class="c-radio-label">
          I consent to Enphase’s
          <a href="https://www4.enphase.com/en-us/legal/privacy-policy" target="_blank">Privacy Policy</a>
          and
          <a href="https://www4.enphase.com/en-us/legal/terms-of-service" target="_blank">Terms of Service</a>
          <br />
          and agree to be contacted by Enphase or its network of installers, at the phone number and/or 
          e-mail address entered here. My consent to these communications is not a condition of purchase 
          and may be revoked at any time and by any reasonable means.
        </p> -->
        <p class="c-radio-label">
          By Submitting this form: (i) I acknowledge having Enphase’s
          <a href="https://enphase.com/legal/privacy-policy" target="_blank">Privacy Policy</a>
          and
          <a href="https://enphase.com/legal/terms-service" target="_blank">Terms of Service</a>
          and I consent to these terms; (ii) I agree to be contacted by Enphase or its network of installers, at the phone number and/or 
          e-mail address entered here.<br> My consent to receiving these communications and to these terms is not a condition of purchase 
          and may be revoked at any time and by any reasonable means.
        </p>
        <p class="c-radio-label note-c-label">
          Note: System size and quote to be finalised by your Enphase Installer​.
        </p>
      </mat-radio-group>
      <div class="invalid-data" *ngIf="
          contactForm.controls.accept.invalid &&
          contactForm.controls.accept.touched
        "></div>
    </div>

    <recaptcha [size]="'invisible'" [hide]="false">
    </recaptcha>
    
    <button type="submit" class="wt-outline-btn orange" [disabled]="contactForm.status === 'INVALID'"
      [ngClass]="{ disabled: contactForm.status === 'INVALID' }">
      SUBMIT
    </button>
  </form>
</div>