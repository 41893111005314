import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RecommendedComponent } from './recommended/recommended.component';
// import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { ThankyouPageComponent } from './thankyou-page/thankyou-page.component';


const routes: Routes = [
  { path: '', component: FrontPageComponent },
  // { path: 'additional-info', component: AdditionalInfoComponent },
  { path: 'your-estimation', component: RecommendedComponent },
  { path: 'your-estimation/:editedUnits', component: RecommendedComponent },
  { path: 'thankyou', component: ThankyouPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], 
  exports: [RouterModule],
})
export class AppRoutingModule {}
