import { Injectable } from '@angular/core';

import baseapi from './base/baseapi.json';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ElecricityBillService {
  constructor(private http: HttpClient) {}
  getHeader(): any {
    return new HttpHeaders()
      .set('enctype', 'multipart/form-data')
      .set('Accept', 'application/json');
  }
  getEBilReports(averageMonthlyElectricityBill: any, pincode: any): any {
    return this.http.get(baseapi.getData, {
      params: { averageMonthlyElectricityBill, pincode },
      headers: this.getHeader(),
      observe: 'response',
    });
  }
  getSlabReports(pincode: any): any {
    return this.http.get(baseapi.slabrates, {
      params: { pincode },
      headers: this.getHeader(),
      observe: 'response',
    });
  }

  editPVSize(
    pincode: any,
    pvSize: any,
    averageMonthlyElectricityBill: any
  ): any {
    return this.http.get(baseapi.getDataForEditPVSize, {
      params: { pincode, pvSize, averageMonthlyElectricityBill },
      headers: this.getHeader(),
      observe: 'response',
    });
  }
  editUnits(
    pincode: any,
    editUnits: any,
    averageMonthlyElectricityBill: any
  ): any {
    return this.http.get(baseapi.editUnits, {
      params: { pincode, editUnits, averageMonthlyElectricityBill },
      headers: this.getHeader(),
      observe: 'response',
    });
  }
}
