<div class="front-page-wrapper">
  <div class="left-section">
    <div class="form-wrapper">
      <h3 class="l-section-h">Help us understand your energy needs</h3>
      <div class="h-seprator"></div>
      <form [formGroup]="eBillForm">
        <div class="form-group">
          <label class="c-label">Pincode</label>
          <div class="position-relative">
            <input type="number"  pattern="/^\d+$/" onKeyPress="if(this.value.length==6) return false" class="c-input pin-input" formControlName="formPincode"
              (keyup)='pincodeChange($event)' />
          </div>
          <span *ngIf="message" class="error">{{ message }}</span>
        </div>
        <div class="form-group">
          <label class="c-label">Average <span class="monthly-bill">monthly</span> electricity bill</label>
          <div class="bill-input-wrapper d-inline-block">
            <span>₹</span>
            <!-- (keyup.enter)="keytab($event) -->
            <input type="number" pattern="[0-9]*" inputmode="numeric" class="c-input bill-input js-bill-focus"
              formControlName="formBill" (keyup.enter)="showMySystem(eBillForm)" (keyup)="billChanged()" />
            <div *ngIf="billFieldDisabled" class="bill-input-overlay"></div>
          </div>
          <span class="arrow-icon" [class]="{ 'is-hidden': isFieldHidden }">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path fill="#303C42"
                d="M23.8535156,11.6464844l-7.5-7.5c-0.1953125-0.1953125-0.5117188-0.1953125-0.7070313,0 s-0.1953125,0.5117188,0,0.7070313L22.2929688,11.5H0.5C0.2236328,11.5,0,11.7236328,0,12s0.2236328,0.5,0.5,0.5h21.7929688 l-6.6464844,6.6464844c-0.1953125,0.1953125-0.1953125,0.5117188,0,0.7070313C15.7441406,19.9511719,15.8720703,20,16,20 s0.2558594-0.0488281,0.3535156-0.1464844l7.5-7.5C24.0488281,12.1582031,24.0488281,11.8417969,23.8535156,11.6464844z">
              </path>
              <linearGradient id="ql34MKYMgiPuAmMxrKTz2a" x1="2.815" x2="22.614" y1="5.852" y2="15.084"
                gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#fff" stop-opacity=".2"></stop>
                <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
              </linearGradient>
              <path fill="url(#ql34MKYMgiPuAmMxrKTz2a)"
                d="M23.8535156,11.6464844l-7.5-7.5c-0.1953125-0.1953125-0.5117188-0.1953125-0.7070313,0 s-0.1953125,0.5117188,0,0.7070313L22.2929688,11.5H0.5C0.2236328,11.5,0,11.7236328,0,12s0.2236328,0.5,0.5,0.5h21.7929688 l-6.6464844,6.6464844c-0.1953125,0.1953125-0.1953125,0.5117188,0,0.7070313C15.7441406,19.9511719,15.8720703,20,16,20 s0.2558594-0.0488281,0.3535156-0.1464844l7.5-7.5C24.0488281,12.1582031,24.0488281,11.8417969,23.8535156,11.6464844z">
              </path>
            </svg>
          </span>
          <div class="units-input-wrapper d-inline-block" [class]="{ 'is-hidden': isFieldHidden }">
            <input type="number" class="c-input units-input" formControlName="formUnit" readonly />
            <p class="i-inf">
              Units
              <span class="units-info-icon" (click)="showTable = !showTable">
                <img src="/assets/images/question_mark.svg" alt="Tariff-table" width="17" height="17" />
                <div class="average-slab-table" *ngIf="showTable">
                  <div class="t-scroller">
                    <p class="t-header">
                      {{ reports.stateName }} average slab wise rates*
                    </p>
                    <!-- <p class="des-row">
                      <span class="fixed-charge-note">F.C Fixed Charges per month</span>
                      <span class="des-col">{{
                        reports.fixedCharge | currency: "INR"
                        }}</span>
                    </p> -->
                    <p class="des-seprator">Energy Charges</p>
                    <!-- KERALA -->
                    <div [ngSwitch]="reports.stateName">
                    <div *ngSwitchCase="'KERALA'">
                      <p class="des-row">Telescopic</p>
                      <div class="row padding-lr-0">
                        <div class="col-6">
                          <p class="des-row">
                            <span class="des-col">Units</span>
                            <span class="des-col">Rate</span>
                            <span class="des-col">F.C.</span>

                          </p>
                        </div>
                        <div class="col-6">
                          <p class="des-row">
                            <span class="des-col">Units</span>
                            <span class="des-col">Rate</span>
                            <span class="des-col">F.C.</span>
                          </p>
                        </div>
                      </div>
                      <div class="row padding-lr-0 border-adj">
                        <div class="col-6" *ngFor="let item of telescopic">
                          <p class="des-row">
                            <span class="des-col">{{ item.units }}</span>
                            <span class="des-col">{{
                              item.energyCharge | currency: "INR"
                              }}</span>
                              <span class="des-col">{{ item.fixedCharge  | currency: "INR" }}</span>
                          </p>
                        </div>
                      </div>
                      <p class="des-row">Non-Telescopic</p>
                      <div class="row padding-lr-0">
                        <div class="col-6">
                          <p class="des-row">
                            <span class="des-col">Units</span>
                            <span class="des-col">Rate</span>
                            <span class="des-col">F.C.</span>
                          </p>
                        </div>
                        <div class="col-6">
                          <p class="des-row">
                            <span class="des-col">Units</span>
                            <span class="des-col">Rate</span>
                            <span class="des-col">F.C.</span>
                          </p>
                        </div>
                      </div>
                      <div class="row padding-lr-0 border-adj">
                        <div class="col-6" *ngFor="let item of nontelescopic">
                          <p class="des-row">
                            <span class="des-col">{{ item.units }}</span>
                            <span class="des-col">{{
                              item.energyCharge | currency: "INR"
                              }}</span>
                              <span class="des-col">{{ item.fixedCharge  | currency: "INR" }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- BIHAR -->
  <div *ngSwitchCase="'BIHAR'">
    <p class="des-row">DS-I (load upto 2kW)</p>
    <div class="row padding-lr-0">
      <div class="col-6">
        <p class="des-row">
          <span class="des-col">Units</span>
          <span class="des-col">Rate</span>
          <span class="des-col">F.C.</span>

        </p>
      </div>
      <div class="col-6">
        <p class="des-row">
          <span class="des-col">Units</span>
          <span class="des-col">Rate</span>
          <span class="des-col">F.C.</span>
        </p>
      </div>
    </div>
    <div class="row padding-lr-0 border-adj">
      <div class="col-6" *ngFor="let item of ds1">
        <p class="des-row">
          <span class="des-col">{{ item.units }}</span>
          <span class="des-col">{{
            item.energyCharge | currency: "INR"
            }}</span>
            <span class="des-col">{{ item.fixedCharge  | currency: "INR"}}</span>
        </p>
      </div>
    </div>
    <p class="des-row">DS-II (3kW - 5kW)</p>
    <div class="row padding-lr-0">
      <div class="col-6">
        <p class="des-row">
          <span class="des-col">Units</span>
          <span class="des-col">Rate</span>
          <span class="des-col">F.C.</span>
        </p>
      </div>
      <div class="col-6">
        <p class="des-row">
          <span class="des-col">Units</span>
          <span class="des-col">Rate</span>
          <span class="des-col">F.C.</span>
        </p>
      </div>
    </div>
    <div class="row padding-lr-0 border-adj">
      <div class="col-6" *ngFor="let item of ds2">
        <p class="des-row">
          <span class="des-col">{{ item.units }}</span>
          <span class="des-col">{{
            item.energyCharge | currency: "INR"
            }}</span>
            <span class="des-col">{{ item.fixedCharge | currency: "INR" }}</span>
        </p>
      </div>
    </div>
    <p class="des-row gx-0">DS-III (above 5kW)</p>
    <div class="row padding-lr-0 gx-0">
      <div class="col-12">
        <p class="des-row">
          <span class="des-col">Units</span>
          <span class="des-col">Rate</span>
          <span class="des-col">F.C.</span>
        </p>
      </div>
      <!-- <div class="col-6">
        <p class="des-row">
          <span class="des-col">Units</span>
          <span class="des-col">Rate</span>
          <span class="des-col">F.C</span>
        </p>
      </div> -->
    </div>
    <div class="row padding-lr-0 gx-0 border-adj">
      <div class="col-12" *ngFor="let item of ds3">
        <p class="des-row">
          <span class="des-col">{{ item.units }}</span>
          <span class="des-col">{{
            item.energyCharge | currency: "INR"
            }}</span>
            <span class="des-col">{{ item.fixedCharge  | currency: "INR"}}</span>
        </p>
      </div>
    </div>
  </div>
<!-- HARYANA -->
  <div *ngSwitchCase="'HARYANA'">
  <p class="des-row">Category - I (Overall consumption &lt; 100)</p>
  <div class="row padding-lr-0">
    <div class="col-6">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
      </p>
    </div>
    <div class="col-6">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
      </p>
    </div>
  </div>
  <div class="row padding-lr-0 border-adj">
    <div class="col-6" *ngFor="let item of cat1">
      <p class="des-row">
        <span class="des-col">{{ item.units }}</span>
        <span class="des-col">{{
          item.energyCharge | currency: "INR"
          }}</span>
          <span class="des-col">{{ item.fixedCharge  | currency: "INR"}}</span>
      </p>
    </div>
  </div>
  <p class="des-row">Category - II (Overall consumption &gt; 100)</p>
  <div class="row padding-lr-0">
    <div class="col-6">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
      </p>
    </div>
    <div class="col-6">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
      </p>
    </div>
  </div>
  <div class="row padding-lr-0 border-adj">
    <div class="col-6" *ngFor="let item of cat2">
      <p class="des-row">
        <span class="des-col">{{ item.units }}</span>
        <span class="des-col">{{
          item.energyCharge | currency: "INR"
          }}</span>
          <span class="des-col">{{ item.fixedCharge  | currency: "INR"}}</span>
      </p>
    </div>
  </div>
</div>
<!--- HIMACHAL PRADESH ---->
<div *ngSwitchCase="'HIMACHAL PRADESH'">
  <p class="des-row gx-0">Slab - I (Overall consumption &lt;= 123)</p>
  <div class="row padding-lr-0 gx-0">
    <div class="col-12">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
      </p>
    </div>
    <!-- <div class="col-6">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C</span>
      </p>
    </div> -->
  </div>
  <div class="row  padding-lr-0 border-adj gx-0">
    <div class="col-12" *ngFor="let item of slab1">
      <p class="des-row">
        <span class="des-col">{{ item.units }}</span>
        <span class="des-col">{{
          item.energyCharge | currency: "INR"
          }}</span>
          <span class="des-col">{{ item.fixedCharge  | currency: "INR"}}</span>
      </p>
    </div>
  </div>
  <p class="des-row">Slab - II (Overall consumption above 123)</p>
  <div class="row gx-0 padding-lr-0">
    <div class="col-6">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
      </p>
    </div>
    <div class="col-6">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
      </p>
    </div>
  </div>
  <div class="row gx-0 padding-lr-0 border-adj">
    <div class="col-6" *ngFor="let item of slab2">
      <p class="des-row">
        <span class="des-col">{{ item.units }}</span>
        <span class="des-col">{{
          item.energyCharge | currency: "INR"
          }}</span>
          <span class="des-col">{{ item.fixedCharge  | currency: "INR"}}</span>
      </p>
    </div>
  </div>
</div>
<!--- TELANGANA ---->
<div *ngSwitchCase="'TELANGANA'">
  <p class="des-row">Category - I (Overall consumption upto 100)</p>
  <div class="row padding-lr-0">
    <div class="col-6">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
      </p>
    </div>
    <div class="col-6">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
      </p>
    </div>
  </div>
  <div class="row padding-lr-0 border-adj">
    <div class="col-6" *ngFor="let item of group1">
      <p class="des-row">
        <span class="des-col">{{ item.units }}</span>
        <span class="des-col">{{
          item.energyCharge | currency: "INR"
          }}</span>
          <span class="des-col">{{ item.fixedCharge  | currency: "INR"}}</span>
      </p>
    </div>
  </div>
  <p class="des-row">Category - II (Overall consumption 100-200)</p>
  <div class="row padding-lr-0">
    <div class="col-6">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
      </p>
    </div>
    <div class="col-6">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
      </p>
    </div>
  </div>

  <div class="row padding-lr-0 border-adj">
    <div class="col-6" *ngFor="let item of group2">
      <p class="des-row">
        <span class="des-col">{{ item.units }}</span>
        <span class="des-col">{{
          item.energyCharge | currency: "INR"
          }}</span>
          <span class="des-col">{{ item.fixedCharge  | currency: "INR"}}</span>
      </p>
    </div>
  </div>
  
  <p class="des-row">Category - III (Overall consumption above 200)</p>
  <div class="row padding-lr-0">
    <div class="col-6">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
      </p>
    </div>
    <div class="col-6">
      <p class="des-row">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span> 
      </p>
    </div>
  </div>
  <div class="row padding-lr-0 border-adj">
    <div class="col-6" *ngFor="let item of group3">
      <p class="des-row">
        <span class="des-col">{{ item.units }}</span>
        <span class="des-col">{{
          item.energyCharge | currency: "INR"
          }}</span>
          <span class="des-col">{{ item.fixedCharge  | currency: "INR"}}</span>
      </p>
    </div>
  </div>
</div>

 <!-- STATES DEFAULT -->
 <div *ngSwitchDefault class="row padding-lr-0 border-adj">
  <div class="row padding-lr-0 gx-0">
    <div [class.col-6]="reports.slabRates.length > 1" [class.col-12]="reports.slabRates.length <= 1">
      <p class="des-row" >
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
        
      </p>
    </div>
    <div class="col-6 "  *ngIf="reports.slabRates.length > 1">
      <p class="des-row ">
        <span class="des-col">Units</span>
        <span class="des-col">Rate</span>
        <span class="des-col">F.C.</span>
      </p>
    </div>
  </div>
  <div class="row padding-lr-0 gx-0 border-adj">
    <div  [class.col-6]="reports.slabRates.length > 1" [class.col-12]="reports.slabRates.length <= 1" *ngFor="let item of reports.slabRates">
      <p class="des-row" >
        <span class="des-col">{{ item.units }}</span>
        <span class="des-col">{{
          item.energyCharge | currency: "INR"
          }}</span>
          <span class="des-col">{{ item.fixedCharge  | currency: "INR"}}</span>
      </p>
    </div>
  </div>
</div>
</div>
<p class="info-text">
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12" height="12" viewBox="0,0,256,256">
    <g fill="#7f7c7c" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.6907,0 -23,10.3093 -23,23c0,12.69071 10.3093,23 23,23c12.69071,0 23,-10.30929 23,-23c0,-12.6907 -10.30929,-23 -23,-23zM25,4c11.60982,0 21,9.39018 21,21c0,11.60982 -9.39018,21 -21,21c-11.60982,0 -21,-9.39018 -21,-21c0,-11.60982 9.39018,-21 21,-21zM25,11c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3zM21,21v2h1h1v13h-1h-1v2h1h1h4h1h1v-2h-1h-1v-15h-1h-4z"></path></g></g>
    </svg>
  (F.C.) Fixed Charges
</p>
<p class="info-text">
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12" height="12" viewBox="0,0,256,256">
    <g fill="#7f7c7c" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.6907,0 -23,10.3093 -23,23c0,12.69071 10.3093,23 23,23c12.69071,0 23,-10.30929 23,-23c0,-12.6907 -10.30929,-23 -23,-23zM25,4c11.60982,0 21,9.39018 21,21c0,11.60982 -9.39018,21 -21,21c-11.60982,0 -21,-9.39018 -21,-21c0,-11.60982 9.39018,-21 21,-21zM25,11c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3zM21,21v2h1h1v13h-1h-1v2h1h1h4h1h1v-2h-1h-1v-15h-1h-4z"></path></g></g>
    </svg>
  Sanctioned load is considered as 2 KW
</p>

<p class="info-text">
  Note: Tariff rates are based on averages and subject to change
  basis your specific location.
</p>
                  </div>
                </div>
                <!-- ./average-slab-table -->
              </span>
              <span><a class="edit-link" (click)="showSlider()">Edit</a></span>
            </p>
          </div>
          <div class="edit-slider-wrapper" *ngIf="showEdit">
            <div class="unit-box">{{ slideValue  }}</div>
            <p class="units-txt">units/month</p>
            <a class="unit-done" (click)="showSlider()">DONE</a>
            <mat-slider class="c-slider" [min]="minUnit" [max]="maxUnit" step="1" [value]="slideValue"
              (input)="slideValue = $event.value || slideValue" (change)="getSliderValue(slideValue)">
            </mat-slider>
          </div>
          <p class="c-info">
            Your bill amount helps us calculate your energy consumption and
            recommend the right system for your energy needs
          </p>
        </div>
        <button type="button" class="c-primary-btn" (click)="showMySystem(eBillForm)"
          [ngClass]="{ 'c-btn-disabled': buttonDisabled }" [disabled]="buttonDisabled">
          Show My System
        </button>
      </form>
    </div>
    <div class="bottom-links">
      <a href="https://enphase.com/legal/terms-service" target="_blank">Terms</a>
      <span> | </span>
      <a href="https://enphase.com/legal/privacy-policy"
        target="_blank">Privacy</a>
    </div>
  </div>
  <div class="right-section">
    <h1 class="main-heading">Go Solar with Enphase</h1>
    <p class="sub-heading">Find the right system size for your home</p>
    <p class="lined-text">
      <span>Enphase Advantage</span>
    </p>
    <div class="icons-wrapper">

      <div class="icon-container">


        <div class="icon">
          <div class="info-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
              <defs>
                <style>
                  .a,
                  .b {
                    fill: none;
                  }

                  .b {
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <g transform="translate(-252 -268)">
                <path class="b"
                  d="M284.086,304.3a18.026,18.026,0,0,1-11.536-16.82v-8.407l11.28-2.51,11.792,2.51v8.407A18.027,18.027,0,0,1,284.083,304.3Z" />
                <path class="b" d="M276.2,281.4" />
                <path class="b" d="M283.83,280.166l8.208,1.78v5.534a14.443,14.443,0,0,1-7.949,12.9" />
                <path class="b" d="M283.861,300.384a14.444,14.444,0,0,1-7.952-12.9V281.95l7.921-1.78" />
                <path class="b" d="M289.469,284.812l-7.081,6.406-2.021-2.39" />
                <path class="b" d="M270.492,306.533" />
                <path class="b"
                  d="M291.933,323.437v-7.684a5.879,5.879,0,0,1,1.193-3.538l3.91-5.138a1.686,1.686,0,0,1,2.187-.573,2.916,2.916,0,0,1,.364,2.674l-1.641,2.137" />
                <path class="b"
                  d="M297.853,311.3l3.246-3.589a3.847,3.847,0,0,0,.792-2.332v-8.736c0-.717-.224-2.474,1.832-2.474a2.746,2.746,0,0,1,2.818,2.351l1.037,10.931a1.889,1.889,0,0,1-.375,1.322l-8.428,10.973a.977.977,0,0,0-.2.59v2.817" />
                <path class="b"
                  d="M270.054,311.315l-1.641-2.137a2.916,2.916,0,0,1,.364-2.674,1.686,1.686,0,0,1,2.187.573l3.91,5.138a5.879,5.879,0,0,1,1.193,3.538v7.684" />
                <path class="b"
                  d="M269.425,323.154v-2.817a.977.977,0,0,0-.2-.59L260.8,308.774a1.889,1.889,0,0,1-.375-1.322l1.037-10.931a2.746,2.746,0,0,1,2.818-2.351c2.056,0,1.832,1.757,1.832,2.474v8.736a3.847,3.847,0,0,0,.792,2.332l3.246,3.589" />
              </g>
            </svg>
          </div>
        </div>
        <p>Safety First</p>

      </div>
      <div class="icon-container">
        <div class="info-icon">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
              <defs>
                <style>
                  .a,
                  .b {
                    fill: none;
                  }

                  .b {
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                </style>
              </defs>
              <g transform="translate(-54 -126)">
                <path class="b" d="M64.75,155.924,85.95,135.2l21.306,21.306" />
                <path class="b" d="M72.035,144.583v-8.721h5.188v4.36" />
                <path class="b" d="M69.276,155.07v19.65h4.416" />
                <path class="b" d="M102.5,155.07v19.65H89.919" />
                <line class="b" y1="2.097" transform="translate(76.437 178.703)" />
                <line class="b" y1="1.578" transform="translate(76.437 174.555)" />
                <line class="b" y1="12.837" transform="translate(76.437 159.018)" />
                <path class="b" d="M81.3,180.8V167.285L86,162.3v-3.5" />
                <line class="b" y1="1.65" transform="translate(86.189 179.15)" />
                <path class="b" d="M86,176.318v-2.749l9.944-9.275v-5.741" />
                <circle class="b" cx="2.783" cy="2.783" r="2.783" transform="translate(73.655 152.645)" />
                <circle class="b" cx="2.783" cy="2.783" r="2.783" transform="translate(83.217 152.645)" />
                <circle class="b" cx="2.783" cy="2.783" r="2.783" transform="translate(93.429 152.645)" />
              </g>
            </svg>
          </div>
          <p>Smart Power</p>
        </div>

      </div>
      <div class="icon-container">
        <div class="info-icon">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
              <defs>
                <style>
                  .a,
                  .b {
                    fill: none;
                  }

                  .b {
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }

                  .c {
                    fill: #fff;
                  }
                </style>
              </defs>
              <path class="b"
                d="M-83.358,48.394A8.975,8.975,0,0,1-85.2,35.835,8.975,8.975,0,0,1-72.639,34,8.974,8.974,0,0,1-70.8,46.558a8.974,8.974,0,0,1-1.836,1.836m-5.361,0a7.18,7.18,0,0,0,7.179-7.179A7.179,7.179,0,0,0-78,34.035a7.179,7.179,0,0,0-7.178,7.178A7.188,7.188,0,0,0-78,48.394Zm-15.256,0h20.6m0,0h9.913M-86.371,44.8H-100.4a.933.933,0,0,0-.933.933v7.108a.933.933,0,0,0,.933.933h44.8a.933.933,0,0,0,.933-.933v-7.1a.933.933,0,0,0-.272-.662A.933.933,0,0,0-55.6,44.8H-69.781"
                transform="translate(110 -11)" />
              <path class="c"
                d="M51.341,7.384l-.278.4a1.53,1.53,0,0,1-1.056.643,1.577,1.577,0,0,1-1.1-.28,3.078,3.078,0,0,0-.806-.473,3.355,3.355,0,0,0,.458-1.7q0-.059,0-.117H50.6V4.3H48.116a1.92,1.92,0,0,1-.5-1.212,1.526,1.526,0,0,1,2.98-.465l1.487-.474A3.087,3.087,0,1,0,46.3,4.3H44.941V5.859h2.05q0,.059,0,.117A1.84,1.84,0,0,1,45.91,7.639a3.1,3.1,0,0,0-1.225.814l-.33.359L45.5,9.868l.33-.359a1.532,1.532,0,0,1,.492-.356l.148-.058a1.521,1.521,0,0,1,1.456.272l.039.03a3.167,3.167,0,0,0,1.85.6,3.023,3.023,0,0,0,.39-.025,3.093,3.093,0,0,0,2.135-1.3l.278-.4Z"
                transform="translate(-16.489 25)" />
            </svg>
          </div>
          <p>Super Savings</p>
        </div>

      </div>
    </div>
  </div>
</div>