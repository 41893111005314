import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

import { LoaderService } from '../app/shared/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('flyIn', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [
        style({ transform: 'translateY(-100%)', opacity: 0 }),
        animate('0.5s ease-out'),
      ]),
    ])
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  loaded = false;
  showLoader = false;

  // @HostListener('window:onbeforeunload', ['$event'])
  // clearLocalStorage(): void {
  //   localStorage.clear();
  // }

  constructor(
    private lService: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private gtmService: GoogleTagManagerService
  ) {

  }

  ngOnInit(): void {
    this.customEvent('India_estimator_landing');
    
    localStorage.clear();

    this.lService.isLoading.subscribe((value: boolean) => {
      this.showLoader = value;
    });
    this.route.queryParams.subscribe((params) => {
      if (
        params.utm_source ||
        params.utm_medium ||
        params.utm_campaign ||
        params.utm_content ||
        params.utm_term
      ) {
        sessionStorage.setItem('utm_source', params?.utm_source || '');
        sessionStorage.setItem('utm_medium', params?.utm_medium || '');
        sessionStorage.setItem('utm_campaign', params?.utm_campaign || '');
        sessionStorage.setItem('utm_content', params?.utm_content || '');
        sessionStorage.setItem('utm_term', params?.utm_term || '');
      }
    });
  }
  customEvent(buttonText: string): void {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'India_estimator_landing',
      pageName: '/'
    };
    this.gtmService.pushTag(gtmTag);
  }

  ngOnDestroy(): void {
    // this.clearLocalStorage();
  }
}
