<div class="main-bg">
  <div class="shadow"></div>
  <div class="page-content">
    <app-header @flyIn></app-header>
    <router-outlet></router-outlet>
  </div>


  <!-- Loader -->
  <div class="page-loading" *ngIf="showLoader">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>