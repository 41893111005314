import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ReCaptchaModule } from 'angular-recaptcha3';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { RecommendedComponent } from './recommended/recommended.component';
import { ConnectWithExpertsComponent } from './connect-with-experts/connect-with-experts.component';
import { ThankyoudialogComponent } from './thankyoudialog/thankyoudialog.component';
import { LessFivehundredComponent } from './less-fivehundred/less-fivehundred.component';
import { LargePVSizeModalComponent } from './large-pvsize-modal/large-pvsize-modal.component';
import { IndiancurrencyPipe } from './shared/indiancurrency.pipe';
import { HttpInterceptorService } from './shared/http-interceptor.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { ThankyouPageComponent } from './thankyou-page/thankyou-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    RecommendedComponent,
    ThankyoudialogComponent,
    ConnectWithExpertsComponent,
    IndiancurrencyPipe,
    LessFivehundredComponent,
    LargePVSizeModalComponent,
    // AdditionalInfoComponent,
    FrontPageComponent,
    ThankyouPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSliderModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatTooltipModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatSnackBarModule,
    AppRoutingModule,
    HttpClientModule,
    ReCaptchaModule.forRoot({
      invisible: {
        sitekey: '6LdGWAkdAAAAAFRWNTMyF-dmwFHHUGTEXtCt5OKQ',
      },
      normal: {
        sitekey: '6LdGWAkdAAAAAFRWNTMyF-dmwFHHUGTEXtCt5OKQ',
      },
      language: 'en'
    }),
  ],
  providers: [
    IndiancurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-WDJV9F' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
