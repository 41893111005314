<div class="front-page-wrapper">
  <div class="left-section">
    <div class="form-wrapper" id="scrollTopOfPage">
      <div class="h-seprator"></div>
      <p class="recom-left-info">
        Location: <span>{{ pin }}</span> <br />
        Average monthly electricity bill: <span>₹ {{ ebill }}</span> <br />
        Energy consumption: <span>{{ units }} units/ mo</span>
      </p>
      <button class="change-details-btn" (click)="editInfo()">
        Change details
      </button>
      <hr>
      <div class="max-width-345">
        <h2 class="savings-heading ">
          <span>Save up to {{formated25yearSavings}}</span> over 25 years with your Enphase system.
        </h2>
        <button type="button" class="c-primary-btn" (click)="openDialog('India_estimator_connect_with_us_button_btf')">
          <img src="/assets/images/contact-enphase.svg" alt="connect" width="20" height="20" />
          Interested? Connect with us
        </button>
      </div>
    </div>
    <div class="bottom-links">
      <a href="https://enphase.com/legal/terms-service" target="_blank">Terms</a>
      <span> | </span>
      <a href="https://enphase.com/legal/privacy-policy"
        target="_blank">Privacy</a>
    </div> <!-- ./bottom-links -->
  </div>
  <div class="right-section">
    <h2 class="steps-heading">For your energy needs, we recommend</h2>
    <div class="max-width-345 dailog-visible">
      <h2 class="savings-heading saving-color">
        <span>Save up to {{formated25yearSavings}}</span> over 25 years with your Enphase system.
      </h2>
      <button type="button" class="c-primary-btn" (click)="openDialog('India_estimator_connect_with_us_button_atf')">
        <img src="/assets/images/contact-enphase.svg" alt="connect" width="20" height="20" />
        Interested? Connect with us
      </button>
    </div>
    <div class="rec-wrapper-main">
    <div class="rec-wrapper" @flyIn *ngIf="bills">
      <div class="rec-block-1">
        <img src="/assets/images/solarpannel.JPG" loading="eager" alt="solar-panels" width="133" />
        <p>
          {{ slideValue }} kW
          <strong>- {{ bills.panelCount }} Solar Panels</strong>
        </p>
        <mat-slider class="c-slider" min="1" max="30" step=".1" [value]="slideValue"
          (input)="slideValue = $event.value || slideValue" (change)="callAPIHear(slideValue)">
        </mat-slider>
        <p>
          {{ bills.idealRoofSize }} sq. ft of roof area required
          <span class="info-icon-1" matTooltipClass="c-tooltip w-max-322"
            matTooltip="The total roof top area is derived basis single panel size 7ft x 3.25ft."
            [matTooltipPosition]="'above'" (click)="rooftooltip.toggle()" #rooftooltip="matTooltip">
            <img src="/assets/images/question_mark.svg" alt="disclaimer" width="14" height="14" />
          </span>
        </p>
      </div>

      <!-- ./rec-block-1 -->
      <div class="rec-block-2">
        <div class="highlight-block">
          <div>
            <img src="/assets/images/money-hand.svg" loading="eager" alt="savings" width="36" height="32" />
          </div>
          <div>
            <p class="savings-text">Savings over 25 years</p>
            <p class="savings-amount">
              {{ bills.savingsFor25Years | indiancurrency }}
              <!-- {{ bills.savingsFor25Years.min | indiancurrency }} - {{ bills.savingsFor25Years.max | indiancurrency }} -->
              <span class="info-icon" (click)="tooltip.toggle()" #tooltip="matTooltip"
              matTooltipClass="c-tooltip w-max-322"
              matTooltip="These are the estimated savings for you over 25 years. Enphase systems are safe, offer industry-leading warranties, and have higher energy generation (even in low-light conditions or shade)."
              [matTooltipPosition]="'above'">
              <img src="/assets/images/question_mark.svg" alt="disclaimer" width="17" height="17" />
            </span>
            </p>
           
          </div>
        </div>
        <!-- ./highlight-block -->
        <div class="main-box">
          <div class="left-box">
            <div class="left-inner-box">

              <!-- total cost -->
              <div class="info-card">
                <img src="/assets/images/total-cost.svg" loading="eager" alt="total-cost" width="22" />
                <div class="mobile-view">
                <p class="label">Total cost <span class="info-icon"
                  matTooltip="This is an estimated cost for your full system, including installation and commissioning charges. Government taxes are extra, as applicable."
                  [matTooltipPosition]="'above'" matTooltipClass="c-tooltip w-max-258" (click)="costtooltip.toggle()"
                  #costtooltip="matTooltip">
                  <img src="/assets/images/question_mark.svg" alt="disclaimer" width="14" height="14" />
              </span></p>
                <p class="values">
                  {{ estPrice.min | indiancurrency }} - {{ estPrice.max | indiancurrency }}
                </p>
              </div>
              </div>
              <!-- total-cost-ends -->

              <!-- subsidy-copy -->
              <div class="net-cost-card show">
                <img src="/assets/images/Subsidy icon.png" loading="eager" alt="subsidy" width="27" />
                <!-- <p>Subsidy</p> -->
                <div>
                <p > Surya Ghar subsidy
                 <span class="values"> {{
                    bills.
                    CentralSubsidy  | indiancurrency              
                  }}</span>
                </p>
                <p style="margin-top:3px" *ngIf="bills.
                stateSubsidy > 0" class="state"> State subsidy
                <span class="values"> {{
                    bills.
                    stateSubsidy | indiancurrency              
                  }}</span>
                </p>
              </div>
              </div>
              <!-- subsidy-copy-ends -->

              <!-- net cost -->
              <div class=" info-card hide">
                <img src="/assets/images/bill.svg" loading="eager" alt="net-cost" width="27" />
                <!-- <p *ngIf="window.innerWidth < 600px">test</p> -->
                <!-- <div class="net-cost-heading"> -->
                  <div style="gap:4px">
                <p>Net cost after subsidy 
                  <span *ngIf="bills.
                  stateSubsidy > 0" class="info-icon"
                    matTooltip="The net cost represents the total cost of your system after deducting the Surya Ghar and state subsidies."
                    [matTooltipPosition]="'above'" matTooltipClass="c-tooltip w-max-258" (click)="costtooltip.toggle()"
                    #costtooltip="matTooltip">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" style="margin-top:3px;" x="0px" y="0px" width="12" height="12" viewBox="0,0,256,256">
                      <g fill="#7f7c7c" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.6907,0 -23,10.3093 -23,23c0,12.69071 10.3093,23 23,23c12.69071,0 23,-10.30929 23,-23c0,-12.6907 -10.30929,-23 -23,-23zM25,4c11.60982,0 21,9.39018 21,21c0,11.60982 -9.39018,21 -21,21c-11.60982,0 -21,-9.39018 -21,-21c0,-11.60982 9.39018,-21 21,-21zM25,11c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3zM21,21v2h1h1v13h-1h-1v2h1h1h4h1h1v-2h-1h-1v-15h-1h-4z"></path></g></g>
                    </svg> -->
                    <img src="/assets/images/question_mark.svg" alt="disclaimer" width="14" height="14" />
                  </span>
                  <span *ngIf="bills.
                  stateSubsidy === 0" class="info-icon"
                    matTooltip="The net cost represents the total cost of your system after deducting the Surya Ghar subsidy."
                    [matTooltipPosition]="'above'" matTooltipClass="c-tooltip w-max-258" (click)="costtooltip.toggle()"
                    #costtooltip="matTooltip">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" style="margin-top:3px;" x="0px" y="0px" width="12" height="12" viewBox="0,0,256,256">
                      <g fill="#7f7c7c" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.6907,0 -23,10.3093 -23,23c0,12.69071 10.3093,23 23,23c12.69071,0 23,-10.30929 23,-23c0,-12.6907 -10.30929,-23 -23,-23zM25,4c11.60982,0 21,9.39018 21,21c0,11.60982 -9.39018,21 -21,21c-11.60982,0 -21,-9.39018 -21,-21c0,-11.60982 9.39018,-21 21,-21zM25,11c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3zM21,21v2h1h1v13h-1h-1v2h1h1h4h1h1v-2h-1h-1v-15h-1h-4z"></path></g></g>
                    </svg> -->
                    <img src="/assets/images/question_mark.svg" alt="disclaimer" width="14" height="14" />
                
                
                </span></p>
                <!-- </div> -->
                <p class="values">
                  {{ bills.newEstimatedPriceAfterSubsidy.min | indiancurrency }} - {{ bills.newEstimatedPriceAfterSubsidy.max | indiancurrency }}
                </p></div>
                </div>
                <!-- net-cost-ends -->
                
                <!-- <p *ngif="bills.
                stateSubsidy " class="values"> State
                  {{
                    bills.
                    stateSubsidy           
                  }}
  
                </p> -->
              <!-- </div> -->
            </div>
            <!-- subsidy starts -->
            <div class="net-cost-card hide">
              <img src="/assets/images/Subsidy icon.png" loading="eager" alt="subsidy" width="27" />
              <!-- <p>Subsidy</p> -->
              <div>
              <p > Surya Ghar subsidy
               <span class="values"> {{
                  bills.
                  CentralSubsidy  | indiancurrency              
                }}</span>
              </p>
              
              <p style="margin-top:3px" *ngIf="bills.
              stateSubsidy > 0" class="state"> State subsidy
              <span class="values"> {{
                  bills.
                  stateSubsidy | indiancurrency              
                }}</span>
              </p>
            </div>
            </div>
            <!-- subsidy-ends -->

            <!-- net-cost-copy -->
            <div class="info-card show">
              <img src="/assets/images/bill.svg" loading="eager" alt="net-cost" width="27" />
              <!-- <p *ngIf="window.innerWidth < 600px">test</p> -->
              <!-- <div class="net-cost-heading"> -->
              <div class="mobile-view">
              <p>Net cost after subsidy 
                <span *ngIf="bills.
                stateSubsidy > 0" class="info-icon"
                  matTooltip="The net cost represents the total cost of your system after deducting the Surya Ghar and state subsidies."
                  [matTooltipPosition]="'above'" matTooltipClass="c-tooltip w-max-258" (click)="costtooltip.toggle()"
                  #costtooltip="matTooltip">
                  <img src="/assets/images/question_mark.svg" alt="disclaimer" width="14" height="14" />
                </span>
                <span *ngIf="bills.
                stateSubsidy === 0" class="info-icon"
                  matTooltip="The net cost represents the total cost of your system after deducting the Surya Ghar subsidy."
                  [matTooltipPosition]="'above'" matTooltipClass="c-tooltip w-max-258" (click)="costtooltip.toggle()"
                  #costtooltip="matTooltip">
                  <img src="/assets/images/question_mark.svg" alt="disclaimer" width="14" height="14" />
                </span>
              </p>
              <p class="values">
                {{ bills.newEstimatedPriceAfterSubsidy.min | indiancurrency }} - {{ bills.newEstimatedPriceAfterSubsidy.max | indiancurrency }}
              </p>
              </div>
              </div>

           
          </div>
          <div class="payback">
            <div class="payback-card">
              <img src="/assets/images/payback-period.svg" alt="payback-period" loading="eager" width="22" />
              <div style="text-align: left!important;" class="mobile-view">
              <p>Payback period <span class="info-icon"
                matTooltip="The amount of time your system will pay back for itself i.e. the time it will take for you to recover your investment."
                [matTooltipPosition]="'above'" matTooltipClass="c-tooltip w-max-322" (click)="paybacktooltip.toggle()"
                #paybacktooltip="matTooltip">
                <img src="/assets/images/question_mark.svg" alt="disclaimer" width="14" height="14" />
            </span></p>
              <p class="years">{{ bills.payBackPeriod.min }} years - {{bills.payBackPeriod.max}} years</p>
            </div>
          </div>
          </div>
          <!-- <div class="info-card">
            <img src="/assets/images/bill.svg" width="30" />
            <p>New Electricity Bill</p>
            <p>
              {{
              bills.newMonthlyElectricityBill
              | currency: "INR":"symbol-narrow":"1.0-1"
              }}
            </p>
            <div class="info-icon" (click)="billtooltip.toggle()" matTooltipClass="c-tooltip w-max-322"
              matTooltip="This amount is only illustrative basis the data provided by you. Your actual bill amount will depend on multiple factors such as sanctioned load, environmental conditions and your electricity consumption pattern."
              [matTooltipPosition]="'above'" #billtooltip="matTooltip">
              <img src="/assets/images/question_mark.svg" width="14" height="14" />
            </div>
          </div> -->
        </div>
      </div>
      <!-- ./rec-block-2 -->
      
    </div> <!-- ./rec-wrapper -->
    <div class="disclaimer-text">
      <svg xmlns="http://www.w3.org/2000/svg" style="margin-top:3px;" x="0px" y="0px" width="12" height="12" viewBox="0,0,256,256">
        <g fill="#7f7c7c" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.6907,0 -23,10.3093 -23,23c0,12.69071 10.3093,23 23,23c12.69071,0 23,-10.30929 23,-23c0,-12.6907 -10.30929,-23 -23,-23zM25,4c11.60982,0 21,9.39018 21,21c0,11.60982 -9.39018,21 -21,21c-11.60982,0 -21,-9.39018 -21,-21c0,-11.60982 9.39018,-21 21,-21zM25,11c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3zM21,21v2h1h1v13h-1h-1v2h1h1h4h1h1v-2h-1h-1v-15h-1h-4z"></path></g></g>
        </svg>
      <!-- <p>Calculation for the payback period includes GST at a rate of 13.9%, as applicable.</p> -->
      <p>{{note}}</p>
    </div>
    </div>
    
    
    <div class="w interested-wrapper" @flyIn *ngIf="bills">
      <div class="inner-section">
        <div class="row m-0 w-100">
          <div class="col-lg-5 col-md-5 col-sm-12 p-0">
            <div class="row m-0">
              <div class="col-3 p-0 text-center">
                <img src="/assets/images/saving.svg" loading="eager" alt="savings" width="40" height="40" />
              </div>
              <div class="col pr-0 pl-0">
                <p><b>Going solar with Enphase was never this easy</b></p>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-7 sol-sm-12 p-0">
            <!-- <p class="responsive-txt-shift">
              Introducing 6 months easy EMI payment option-Make sure to ask your
              Enphase Solar Expert.
            </p> -->
            <p class="responsive-txt-shift">
              Introducing EMI payment options. Make sure to ask your
              Enphase solar expert for details.
            </p>
          </div>
        </div>
      </div>
    </div> <!-- ./interested-wrapper -->
    <div class="note-wrapper">
    <div class="recommended-footer-text">
      <p>Note: This tool provides estimates only with some of the data being fetched from third party sources.
      Actuals may depend on site specific factors and market variations and should be verified with an installer.</p>
      <p>Calculations regarding subsidies and electricity tariffs may change and should be verified on the 
      DISCOM/government website. </p>
    </div>
  </div>
  </div>
</div>

<!-- <app-pdf-document></app-pdf-document> -->