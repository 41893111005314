<div class="thankyou-modal text-center">
  <button class="close-btn" mat-dialog-close>
    <img src="/assets/images/close_icon.svg" alt="close" width="14" height="14" />
  </button>

  <p class="mb-20 grey">
    We want to understand your system requirements better.
    <br />
    <br />
    <small class="grey">Please mail us a copy of your electricity bill at</small><br />
    <small><a href = "mailto: india@enphase.com" class="blue">india@enphase.com</a> and we will reach out to you.</small>
  </p>
</div>
