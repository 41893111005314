<button class="close-btn" mat-dialog-close>
  <img src="/assets/images/close_icon.svg" alt="close" width="14" height="14" />
</button>
<div class="modal-container">
  <div class="d-flex form-top-layout">
    <div>
      <img src="/assets/images/safety-first-orange.svg" loading="eager" alt="safety-first" width="24" />
      <p>Safety First</p>
    </div>
    <div>
      <img src="/assets/images/smart-power-orange.svg" loading="eager" alt="smart-power" width="21" />
      <p>Smart Power</p>
    </div>
    <div>
      <img src="/assets/images/super-savings-orange.svg" loading="eager" alt="super-savings" width="36" />
      <p>Super Savings</p>
    </div>
  </div>
  <!-- <p class="m-title">
    Required solar system size is very large.
    Please share your contact details for a custom consultation.
  </p>  -->
  <p class="m-title">
    Considering the size of the required solar system, we would appreciate your contact details for a personalized solar consultation.
  </p>
  <form class="c-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <div class="validation-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>Full Name<span>*</span></mat-label>
        <input matInput placeholder="Full Name" formControlName="name" />
      </mat-form-field>
      <div class="invalid-data" *ngIf="
          contactForm.controls.name.invalid && contactForm.controls.name.touched
        ">
        <div *ngIf="contactForm.controls.name.errors?.required">
          Name is required
        </div>
      </div>
    </div>
    <div class="validation-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>Phone Number<span>*</span></mat-label>
        <input type="tel" matInput placeholder="Phone Number" formControlName="phone" />
        <span matPrefix>+91</span>
      </mat-form-field>
      <div class="invalid-data" *ngIf="
          contactForm.controls.phone.invalid &&
          contactForm.controls.phone.touched
        ">
        <div *ngIf="contactForm.controls.phone.errors?.required">
          Phone Number is required
        </div>
        <div *ngIf="contactForm.controls.phone.errors?.pattern">
          Please Enter a valid Phone Number
        </div>
        <div *ngIf="
            contactForm.controls.phone.errors?.minlength ||
            contactForm.controls.phone.errors?.maxlength
          ">
          Phone Number must be 10 digits long
        </div>
      </div>
    </div>
    <div class="validation-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" formControlName="email" />
      </mat-form-field>
      <div class="invalid-data" *ngIf="
          contactForm.controls.email.invalid &&
          contactForm.controls.email.touched
        ">
        <div *ngIf="contactForm.controls.email.errors?.pattern">
          Please Enter a valid Email
        </div>
      </div>
    </div>
    <div class="validation-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>Pincode<span>*</span></mat-label>
        <input matInput placeholder="Pincode" formControlName="pincode" readonly />
      </mat-form-field>
      <div class="invalid-data" *ngIf="
          contactForm.controls.pincode.invalid &&
          contactForm.controls.pincode.touched
        ">
        <div *ngIf="contactForm.controls.pincode.errors?.required">
          Pincode is required
        </div>
        <div *ngIf="contactForm.controls.pincode.errors?.pattern">
          Please Enter a valid Pincode
        </div>
        <div *ngIf="
            contactForm.controls.pincode.errors?.minlength ||
            contactForm.controls.pincode.errors?.maxlength
          ">
          Pincode must be 6 digits long
        </div>
      </div>
    </div>
    <mat-radio-group aria-label="Accept Privacy Policy and Terms and Condition" formControlName="accept">
      <mat-radio-button value="1"></mat-radio-button>
      <!-- <p class="c-radio-label">
        I consent to Enphase’s
        <a href="https://www4.enphase.com/en-us/legal/privacy-policy" target="_blank">Privacy Policy</a>
        and
        <a href="https://www4.enphase.com/en-us/legal/terms-of-service" target="_blank">Terms of Service</a>
        <br />
        and agree to be contacted by Enphase or its network of installers, at the phone number and/or
        e-mail address entered here. My consent to these communications is not a condition of purchase
        and may be revoked at any time and by any reasonable means.
      </p> -->
      <p class="c-radio-label">
        By Submitting this form: (i) I acknowledge having Enphase’s
        <a href="https://enphase.com/legal/privacy-policy" target="_blank">Privacy Policy</a>
        and
        <a href="https://enphase.com/legal/terms-service" target="_blank">Terms of Service</a>
        and I Consent to these terms; (ii) I agree to be contacted by Enphase or its network of installers, at the phone number and/or
        e-mail address entered here.<br> My consent to receiving these communications and to these terms is not a condition of purchase
        and may be revoked at any time and by any reasonable means.
      </p>
      <p class="c-radio-label note-c-label">
        Note: System size and quote to be finalised by your Enphase Installer​.
      </p>
    </mat-radio-group>

    <recaptcha [size]="'invisible'" [hide]="false">
    </recaptcha>

    <button type="submit" class="wt-outline-btn orange" [disabled]="contactForm.status === 'INVALID'"
      [ngClass]="{ disabled: contactForm.status === 'INVALID' }">
      SUBMIT
    </button>
  </form>
</div>