<div class="front-page-wrapper">
    <div class="left-section">
    <div class="thankyou-modal text-center ">
      
        <h1 class="orange mb-1 t-title">Thank you for your interest in Enphase.</h1>
        <p class="mb-2 fs">We will be in touch soon.</p>
        <p class="mb-1 fs-s"> 
          <small
          ><a href="https://www.enphase.com/en-in" target="_blank" class="blue"
            >Visit our website</a
          ></small
        > to learn more about Enphase </p>
        
        <div class="align-btn-center">
          <div class="pdf-tooltip-wrapper">
            <div>
              <small>You can also </small>
              <span>
                <button class="change-details-btn" (click)="downloadPDF()">
                  <span class="down-bord">Download your estimate</span>
                    <img src="/assets/images/surface1.svg" alt="download" width="15.85" height="20" />
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      </div>
      
</div>