import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { ElecricityBillService } from '../shared/elecricity-bill.service';
import { LoaderService } from '../shared/loader.service';
import { NotificationsService } from '../shared/notifications.service';
import { LessFivehundredComponent } from '../less-fivehundred/less-fivehundred.component';
import { LargePVSizeModalComponent } from '../large-pvsize-modal/large-pvsize-modal.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ConnectWithExpertsComponent } from '../connect-with-experts/connect-with-experts.component';
@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: [
    '../get-started/get-started.component.scss',
    '../average-bill/average-bill.component.scss',
    './front-page.component.scss',
  ],
})
export class FrontPageComponent {


  // @ViewChild('swiper') swiper: ElementRef;

  eBillForm: FormGroup;
  billFieldDisabled = true;
  buttonDisabled = true;
  showUnitsInfo = false;
  slideValue = 0;
  minUnit = 0;
  maxUnit = 0;
  showTable = false;
  reports: any;
  kSlabRates = [];
  telescopic: any[] = [];
  nontelescopic: any[] = [];
  showEdit = false;
  unitsSlideValueChanged = false;
  isEditPage = false;
  stateName: any;
  pvSize = 0;
  pinCodeTimeout: any;
  message: string = '';
  isFieldHidden = true;
  prevBillValue = '';
  billUpdated = false;
  sliderValueChanged = false;
  billTimeout: any;
  element : any

  biharSlabRates = [];  
  ds1: any[] = [];
  ds2: any[] = [];
  ds3: any[] = [];
 
  haryanaSlabRates = []; 
  cat1: any[] = [];
  cat2: any[] = [];
 
  hpSlabRates = [];  
  slab1: any[] = [];
  slab2: any[] = [];
 
  tgSlabRates = [];  
  group1: any[] = [];
  group2: any[] = [];
  group3: any[] = [];


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private slabService: ElecricityBillService,
    private loadService: LoaderService,
    public dialog: MatDialog,
    private notificationService: NotificationsService,
    private gtmService: GoogleTagManagerService,
    private elementRef: ElementRef,
  ) {
    this.eBillForm = this.formBuilder.group({
      formPincode: ['', Validators.required],
      formBill: ['', Validators.required],
      formUnit: [''],
    });
    this.isEditPage = Boolean(localStorage.getItem('editPage')) || false;
    if (this.isEditPage) {
      this.addUnitsFromLocalstorage();
    }
  }

  openDialogForIcon(name: string) {
    //this.dialog.open(ConnectWithExpertsComponent)
  }

  keytab(event: any) {
    // let element =
      this.element = this.elementRef.nativeElement.getElementsByClassName('js-bill-focus')[0];
        this.element.focus();
        this.element.click();
  }

  addUnitsFromLocalstorage(): void {
    const pin = localStorage.getItem('pincode');
    const bill = localStorage.getItem('electricityBill');
    if (pin && bill) {
      this.eBillForm.patchValue({
        formPincode: pin,
        formBill: bill,
      });
      this.billFieldDisabled = false;
      this.slabDataFromAPI(pin, bill);
      this.getEBilReports(bill, pin);
    }
  }

  pincodeChange(event: any) {
    let formPincode = this.eBillForm.get('formPincode')?.value;
    let formBill = this.eBillForm.get('formBill')?.value;
    let form = this.eBillForm.get('formPincode');
    // let test = /^\d{6}$/
    // convert number with Scientific Notation into Normal Representation
    const bill = (formBill || 0).toLocaleString('fullwide', {
      useGrouping: false,
    });
    if (String(formPincode) === '' || formPincode === null || String(formPincode).length === 0 ) {
      // formm.value('');
      // this.eBillForm.patchValue({formPincode: ''});
      this.eBillForm.patchValue({formPincode: String(formPincode).split('-')[0]});
      this.message = '*Pincode should not be blank';
      this.billFieldDisabled = true;
      this.buttonDisabled =true;
    } 

    else if (String(formPincode).includes('.')) {

      this.eBillForm.patchValue({formPincode: String(formPincode).split('.')[0]});
      // this.message = 'Pincode should not contain "."'
      this.billFieldDisabled = true;
      this.buttonDisabled = true;
  }
//   else if (String(formPincode).includes('-')) {
//     this.message = 'Pincode should not contain "-" *';
//     this.billFieldDisabled = true;
//     this.buttonDisabled = true;
// }
  
     else if (String(formPincode).length > 6) {
      
      // String(formPincode) === " ";
      this.message = '*Pincode should not exceed more than 6 digits';
      this.billFieldDisabled = true;
      this.buttonDisabled =true;
    } 
    else if (String(formPincode).length >0 && String(formPincode).length < 6) {
      this.message = '*Please enter a valid pincode';
      this.billFieldDisabled = true;
      this.buttonDisabled =true;
    } 
    else {
      this.message = '';
      this.eBillForm.get('formBill')?.reset();
      this.eBillForm.get('formUnit')?.reset();
      this.isFieldHidden = true;
      this.slabDataFromAPI(formPincode, bill);
    }
    // else{
    //   this.message = 'Please enter valid pincode *';
    //   this.billFieldDisabled = true;
    //   this.buttonDisabled =true;
    // }
  }

  billChanged() {
    let formPincode = this.eBillForm.get('formPincode')?.value;
    let formBill = this.eBillForm.get('formBill')?.value;

    // if (String(formBill).includes('-') || String(formBill) === '' || formBill === null){
    // this.eBillForm.patchValue({formBill: String(formBill).split('-')[0]});
    // this.buttonDisabled = true;
    // this.isFieldHidden = true;
    // this.showUnitsInfo = false;
    // }

    console.log('billamt',formBill)
    // convert number with Scientific Notation into Normal Representation
    const bill = (formBill || 0).toLocaleString('fullwide', {
      useGrouping: false,
    });

    this.checkIfBillUpdated(bill);
    this.prevBillValue = this.eBillForm.controls['formBill'].value;
    clearTimeout(this.billTimeout);
    this.billTimeout = setTimeout(() => {
      if (Number(bill) !== 0 && Number(bill) >= 300) {
        this.getEBilReports(bill, formPincode);
      } else {
        this.showUnitsInfo = false;
        if (formBill == '' || formBill == null || formBill < 300) {
          this.buttonDisabled = true;
          this.isFieldHidden = true;
          this.showUnitsInfo = false;
          return;
        }
        
      }
    }, 500);
  }

  checkIfBillUpdated(bill: any) {
    if (bill == this.prevBillValue) {
      this.billUpdated = false;
    } else {
      this.billUpdated = true;
    }
  }

  isPinValid(pin: any): boolean {
    window.clearInterval(this.pinCodeTimeout);
    this.pinCodeTimeout = window.setInterval(function () {
    
      return true;
    }, 500);
    return false;
  }

  slabDataFromAPI(pin: any, bill: any): void {
    this.loadService.showLoader();
    this.slabService.getSlabReports(pin).subscribe(
      (res: any) => {
        if (res.status === 200) {

          if (res.body.message) {
            this.message = res.body.message;
            this.loadService.hideLoader();
            return;
          } else {
            this.reports = res.body.result;
            if (this.reports?.stateName === 'KERALA') {
              this.telescopic = [];
              this.nontelescopic = [];
              this.kSlabRates = res.body.result.slabRates;
              for (let i = 0; i < this.kSlabRates.length; i++) {
                if (i < 5) {
                  this.telescopic.push(this.kSlabRates[i]);
                } else {
                  this.nontelescopic.push(this.kSlabRates[i]);
                }
              }
            }
            
            if(this.reports?.stateName === 'BIHAR'){
              this.ds1 = [];
              this.ds2 = [];
              this.ds3 = [];
              this.biharSlabRates = res?.body?.result?.slabRates
              for (let i=0; i<this.biharSlabRates.length; i++){
                if(i<2){
                  this.ds1.push(this.biharSlabRates[i])
                }else if(i>=2 && i<4){
                  this.ds2.push(this.biharSlabRates[i])
                }else if(i>=4){
                  this.ds3.push(this.biharSlabRates[i])
                }
              }
            }

            if(this.reports?.stateName === 'HARYANA'){
              this.cat1 = [];
              this.cat2 = [];
              this.haryanaSlabRates = res.body.result.slabRates
              for (let i=0; i<this.haryanaSlabRates.length; i++){
                if(i<2){
                  this.cat1.push(this.haryanaSlabRates[i])
                }else{
                  this.cat2.push(this.haryanaSlabRates[i])
                }
              }
            }

            if(this.reports?.stateName === 'HIMACHAL PRADESH'){
              this.slab1 = [];
              this.slab2 = [];
              this.hpSlabRates = res.body.result.slabRates
              for (let i=0; i<this.hpSlabRates.length; i++){
                if(i<1){
                  this.slab1.push(this.hpSlabRates[i])
                }else{
                  this.slab2.push(this.hpSlabRates[i])
                }
              }
            }

            if(this.reports?.stateName === 'TELANGANA'){
              this.group1 = [];
              this.group2 = [];
              this.group3 = [];
              this.tgSlabRates = res.body.result.slabRates
              for (let i=0; i<this.tgSlabRates.length; i++){
                if(i<2){
                  this.group1.push(this.tgSlabRates[i])
                }else if(i>=2 && i<4){
                  this.group2.push(this.tgSlabRates[i])
                }else if(i>=4){
                  this.group3.push(this.tgSlabRates[i])
                }
              }
            }
          }
          localStorage.setItem('pincode', pin);
          localStorage.setItem('stateName', res.body.result.stateName);
          if (res.body.result) {
            this.message = '';
            // this.router.navigate(['/bill']);
            this.billFieldDisabled = false;
            this.keytab(res.body.result);
            // this.display();
          }
        }
        if (res.status === 406) {
          this.notificationService.warning('Something Went Wrong');
        }
        if (res.status === 500) {
          this.notificationService.error('Internal Error');
        }
        this.loadService.hideLoader();
      },
      (err: any) => {
        this.loadService.hideLoader();
        this.notificationService.error('Error in fetching data');
      }
    );
  }

  getEBilReports(bill: any, pincode: any): void {
    this.loadService.showLoader();
    this.slabService.getEBilReports(bill, pincode).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.isFieldHidden = false;
          this.showUnitsInfo = true;
          this.pvSize = res.body.result?.pvSize;
          const steMinMax = res.body.result?.units;
      
          localStorage.setItem('electricityBill', bill);
          localStorage.setItem(
            'RecommendedData',
            JSON.stringify(res.body.result)
          );
          this.minUnit = Math.floor(steMinMax - (steMinMax * 15) / 100);
          this.maxUnit = Math.floor(steMinMax + (steMinMax * 15) / 100);
          this.slideValue = res.body.result.units;
          if (!this.eBillForm.controls['formUnit'].value || this.billUpdated) {
            this.eBillForm.patchValue({
              formUnit: res.body.result.units,
            });
          }
          this.buttonDisabled = false;
          // this.sliderValueChanged = true;

          // if (!this.sliderValueChanged) {
          //   this.slideValue = res.body.result.units;
          // }
          // this.sliderValueChanged = false;
          localStorage.setItem('unit', JSON.stringify(this.slideValue));
        }
        if (res.status === 406) {
          this.isFieldHidden = true;
          this.showUnitsInfo = false;
          this.notificationService.warning('Something Went Wrong');
        }
        if (res.status === 500) {
          this.isFieldHidden = true;
          this.showUnitsInfo = false;
          this.notificationService.error('Internal Error');
        }
        this.loadService.hideLoader();
      },
      (err: any) => {
        this.isFieldHidden = true;
        this.showUnitsInfo = false;
        this.notificationService.error('Error in fetching data');
      }
    );
  }

  showMySystem(eBillForm: any) {
    this.stateName = localStorage.getItem('stateName');
    if (eBillForm.value.formBill == '' || this.buttonDisabled) {
      return;
    }
    if (eBillForm.value.formBill !== '') {
      if (
        (this.stateName === 'DELHI' ||
          this.stateName === 'UTTAR PRADESH' ||
          this.stateName === 'GURGAON' ||
          this.stateName === 'HARYANA' ||
          this.stateName === 'KERALA') &&
        this.eBillForm.value.formBill < 750
      ) {
        this.openDialog();

      } else if (
        (this.stateName !== 'DELHI' ||
          this.stateName !== 'UTTAR PRADESH' ||
          this.stateName !== 'GURGAON' ||
          this.stateName !== 'HARYANA' ||
          this.stateName !== 'KERALA') &&
        this.eBillForm.value.formBill < 750
      ) {
        this.openDialog();
      } else if (this.pvSize > 30) {
        this.openFormDialog();
      } else {
        if (!this.isEditPage) {
          if (!localStorage.getItem('formSubmitted')) {
            this.customEvent('India_estimator_show_my_system');
          }
        }
        localStorage.setItem('unit', String(this.slideValue));
        if (this.unitsSlideValueChanged) {
          this.router.navigate(['/your-estimation', this.slideValue]);
        } else {
          this.router.navigate(['/your-estimation']);
        }
      }
    }
  }

  customEvent(buttonText: String): void {
    // push GTM data layer with a custom event
    const gtmTag = {
      data: 'button-click',
      event: buttonText,
    };
    this.gtmService.pushTag(gtmTag);
  }

  getSliderValue(e: any): void {
    this.sliderValueChanged = true;
    this.eBillForm.patchValue({
      formUnit: e
    });
    this.slideValue = e;
    this.unitsSlideValueChanged = true;
    localStorage.setItem('unit', e.toString());
    
  }

  showSlider(): void {
    this.showEdit = !this.showEdit;
  }

  openDialog(): void {
    if (!localStorage.getItem('formSubmitted')) {
      this.lessMonthlyBillEvent('India_estimator_less_amount');
    }
    this.dialog.open(LessFivehundredComponent);
  }

  lessMonthlyBillEvent(buttonText: string): void {
    // push GTM data layer with a custom event
    const gtmTag = {
      data: 'button-click',
      event: buttonText,
    };
    this.gtmService.pushTag(gtmTag);
  }

  openFormDialog(): void {
    if (!localStorage.getItem('formSubmitted')) {
      this.largePVSizeEvent('India_estimator_large_pv_size');
    }
    this.dialog.open(LargePVSizeModalComponent);
    this.buttonDisabled = true;
  }

  largePVSizeEvent(buttonText: string): void {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'button-click',
      data: buttonText,
    };
    this.gtmService.pushTag(gtmTag);
  }
}
