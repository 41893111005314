import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import baseapi from './base/baseapi.json';
@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(private http: HttpClient) {}
  getHeader(): any {
    return new HttpHeaders()
      .set('enctype', 'multipart/form-data')
      .set('Accept', 'application/json');
  }
  sendMail(data: any): any {
    return this.http.post(baseapi.sendMail, data, {
      headers: this.getHeader(),
      observe: 'response',
    });
  }
}
